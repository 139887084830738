import { useHistory, useParams } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ROUTES } from '../../constants/routes';
import { generateUrl } from '../../routes/util';
import SimpleTooltip from '../simple-tooltip';
import { NavCode, TemplateHistory } from '../survey-templates/types';
import { SurveyType } from '../../types/survey';
import { useToggle } from '@hooks/useToggle';
import { DeleteConfirmation } from '@g17eco/molecules';
import { useBulkSurveysDeleteMutation } from '@api/survey-templates-history';
import { useSiteAlert } from '../../hooks/useSiteAlert';
import { SURVEY } from '@constants/terminology';

interface TemplateHistoryOptionsProps {
  templateHistory: TemplateHistory;
  isLoading: boolean;
  refreshSurveysScope: (refreshHistoryId: string) => void;
  disabled?: boolean;
}

export const TemplateHistoryOptions = (props: TemplateHistoryOptionsProps) => {
  const { templateHistory, isLoading, refreshSurveysScope, disabled = false } = props;
  const [isOpen, toggleDropdown] = useToggle(false);
  const [isConfirmingDelete, toggleDelete] = useToggle(false);
  const history = useHistory();
  const { initiativeId = '', templateId = '' } = useParams<{ initiativeId: string; templateId: string }>();
  const [bulkDeleteSurveys, { isLoading: isDeleting }] = useBulkSurveysDeleteMutation();
  const { addSiteAlert, addSiteError } = useSiteAlert();

  const handleGoTo = (page: NavCode) => {
    return history.push(
      `${generateUrl(ROUTES.SURVEY_TEMPLATES_HISTORY, {
        initiativeId,
        templateId,
        historyId: templateHistory._id,
        page,
      })}`
    );
  };

  const handleDelete = async () => {
    try {
      await bulkDeleteSurveys({ templateId, historyId: templateHistory._id });
      addSiteAlert({ content: `Successfully started a bulk ${SURVEY.PLURAL} deletion process` });
    } catch (e) {
      addSiteError(e);
    }
  };

  return (
    <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
      <SimpleTooltip text={'Access this template\'s configuration, units or scope'}>
        <DropdownToggle disabled={disabled || isDeleting} color='transparent' outline className='px-2'>
          <i className='fa-solid fa-bars' />
        </DropdownToggle>
      </SimpleTooltip>
      <DropdownMenu>
        <DropdownItem onClick={() => handleGoTo(NavCode.Configuration)}>
          <i className='fa-light fa-layer-group mr-2' />
          Template used
        </DropdownItem>
        <DropdownItem onClick={() => handleGoTo(NavCode.History)}>
          <i className='fa-solid fa-exclamation-triangle mr-2' />
          View failures
        </DropdownItem>
        {templateHistory.snapshot.type === SurveyType.Default ? (
          <DropdownItem disabled={isLoading} onClick={() => refreshSurveysScope(templateHistory._id)}>
            <i className='fa-light fa-pencil mr-2' />
            Refresh scope
          </DropdownItem>
        ) : null}
        <DropdownItem onClick={toggleDelete}>
          <i className='fa-solid fa-trash text-ThemeDangerMedium mr-2' />
          Delete
        </DropdownItem>
      </DropdownMenu>
      {isConfirmingDelete ? (
        <DeleteConfirmation
          title={`Delete ${SURVEY.PLURAL}`}
          text={`Are you sure? Once these ${SURVEY.PLURAL} have been deleted they cannot be recovered.`}
          toggle={toggleDelete}
          handleConfirm={handleDelete}
        />
      ) : null}
    </Dropdown>
  );
};
