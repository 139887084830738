import Select, { Props as BaseSelectProps, GroupBase } from 'react-select';
import { DropdownIndicator, HiddenDropdownIndicator, Option, SelectStyleProps, getStyles } from './SelectFactory';
import { FormGroup, Label, LabelProps } from 'reactstrap';

export type LabelSelectProps = BaseSelectProps<Option | null, false, GroupBase<Option | null>> &
  SelectStyleProps & {
    labelProps?: LabelProps;
  };

export const LabelSelect = (props: LabelSelectProps) => {
  const { labelProps, isTransparent, isFlexibleSize, isMenuPortalTargetBody = false, showDropdownIndicator = true, ...rest } = props;

  return (
    <FormGroup>
      {labelProps ? (
        <Label for={labelProps.for} className={`text-black fw-medium ${labelProps.className}`}>
          {labelProps.title}
        </Label>
      ) : null}
      <Select
        components={{ DropdownIndicator: showDropdownIndicator ? DropdownIndicator : HiddenDropdownIndicator }}
        styles={getStyles({
          active: Boolean(!props.isDisabled),
          isMulti: false,
          isTransparent,
          isFlexibleSize,
          isMenuPortalTargetBody,
        })}
        menuPortalTarget={isMenuPortalTargetBody ? document.body : null}
        {...rest}
      />
    </FormGroup>
  );
};
