import { useParams } from 'react-router-dom';
import { naturalSort } from '../../../utils';
import { formatDate } from '../../../utils/date';
import Table, { TableColumn } from '../../table/Table';
import { RouteParams, TemplateTableStats } from '../types';
import { TemplateDropdownOptions } from './TemplateDropdownOptions';
import { useGetTemplatesQuery } from '../../../api/survey-templates';

const columns: TableColumn<TemplateTableStats>[] = [
  {
    id: 'templates',
    Header: 'Templates',
    accessor: 'name',
  },
  {
    id: 'type',
    Header: 'Template type',
    accessor: 'type',
  },
  {
    id: 'levelCreated',
    Header: 'Level created',
    accessor: (row: TemplateTableStats) => row.initiative.name,
  },
  {
    id: 'createdDate',
    Header: 'Created date',
    accessor: (row: TemplateTableStats) => formatDate(row.created),
    sortType: (a, b) => naturalSort(a.original.created, b.original.created),
  },
  {
    id: 'lastEdit',
    Header: 'Last edit',
    accessor: (row: TemplateTableStats) => formatDate(row.lastUpdated),
    sortType: (a, b) => naturalSort(a.original.lastUpdated, b.original.lastUpdated),
  },
  {
    id: 'settings',
    Header: '',
    accessor: (row: TemplateTableStats) => <TemplateDropdownOptions template={row} />,
    disableSortBy: true,
  },
];

export const TemplateTable = () => {
  const { initiativeId } = useParams<RouteParams>();
  const query = useGetTemplatesQuery(initiativeId);

  return (
    <Table
      className='mt-3'
      defaultSortIcon={null}
      showRowCount={false}
      responsive={true}
      pageSize={10}
      columns={columns}
      data={query.data ?? []}
    />
  );
};
