import { NO_ACCESS_MESSAGE } from '@constants/assurance';
import { UtrvStatus } from '@constants/status';
import { AssuranceListPortfolio, AssuranceStatus } from '@g17eco/types/assurance';
import { CurrentUserData } from '@reducers/current-user';
import { getFullName } from '@utils/user';
import { DelegationOption, ReportOption, StatusOption } from './AssuranceListFilters';

export const getAssurerName = (assurancePortfolio: AssuranceListPortfolio) => {
  if (!assurancePortfolio.assurers) {
    return '';
  }
  const firstAdmin = assurancePortfolio.assurers.find((user) => user.isAdmin);

  if (!firstAdmin) {
    return '';
  }

  return getFullName(firstAdmin, '-');
};

export const hasPermission = (assurancePortfolio: AssuranceListPortfolio, currentUser: CurrentUserData) => {
  return assurancePortfolio.assurers && assurancePortfolio.assurers.some((assurer) => assurer._id === currentUser._id);
};

export const getStatusList = (assurancePortfolio: AssuranceListPortfolio) => {
  const isCompleted = assurancePortfolio.universalTrackerValueAssurances
    .filter((utrvAssurance) => utrvAssurance.status === AssuranceStatus.Completed)
    .map((utrvAssurance) => utrvAssurance.utrvId);
  const isRejected = assurancePortfolio.universalTrackerValueAssurances
    .filter((utrvAssurance) => utrvAssurance.status === AssuranceStatus.Rejected)
    .map((utrvAssurance) => utrvAssurance.utrvId);
  const questionStatuses = assurancePortfolio.universalTrackerValues.reduce(
    (accumulator, utrv) => {
      if (isCompleted.includes(utrv._id)) {
        return accumulator;
      }
      return {
        notReady: utrv.status !== UtrvStatus.Verified ? [...accumulator.notReady, utrv._id] : accumulator.notReady,
        isVerified:
          utrv.status === UtrvStatus.Verified ? [...accumulator.isVerified, utrv._id] : accumulator.isVerified,
      };
    },
    { notReady: [] as string[], isVerified: [] as string[] }
  );
  return {
    ...questionStatuses,
    isCompleted,
    isRejected,
  };
};

export const isCompleted = (assurancePortfolio: AssuranceListPortfolio) => {
  return assurancePortfolio.status === AssuranceStatus.Completed;
};

export const hasDeletedSurvey = (assurancePortfolio: AssuranceListPortfolio) => {
  return !!assurancePortfolio.survey.deletedDate;
};

export const getRowTooltip = (assurancePortfolio: AssuranceListPortfolio, hasAccess: boolean) => {
  if (!hasAccess) {
    return NO_ACCESS_MESSAGE;
  }
  return hasDeletedSurvey(assurancePortfolio)
    ? `The associated report has been deleted by ${assurancePortfolio.initiative?.name} and is no longer accessible.`
    : '';
};

export const isMatchDelegationFilter = ({
  assurancePortfolio,
  filter,
  currentUser,
}: {
  assurancePortfolio: AssuranceListPortfolio;
  filter: DelegationOption;
  currentUser: CurrentUserData | undefined;
}) => {
  if (!currentUser) {
    return false;
  }
  const hasAccess = hasPermission(assurancePortfolio, currentUser);
  switch (filter) {
    case DelegationOption.Me:
      return hasAccess;
    case DelegationOption.ExcludeMe:
      return !hasAccess;
    case DelegationOption.Anyone:
    default:
      return true;
  }
};

export const isMatchReportFilter = (assurancePortfolio: AssuranceListPortfolio, filter: ReportOption) => {
  switch (filter) {
    case ReportOption.ExcludeDeleted:
      return !hasDeletedSurvey(assurancePortfolio);
    case ReportOption.IncludeDeleted:
    default:
      return true;
  }
};

export const isMatchStatusFilter = (assurancePortfolio: AssuranceListPortfolio, status: StatusOption) => {
  switch (status) {
    case StatusOption.Assured:
      return isCompleted(assurancePortfolio);
    case StatusOption.InProgress:
      return !isCompleted(assurancePortfolio);
    case StatusOption.All:
    default:
      return true;
  }
};