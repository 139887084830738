import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface Props {
  title: string | JSX.Element;
  content: string | JSX.Element;
  confirmButton: JSX.Element;
  isOpen: boolean;
  handleCancel: () => void;
}

export const ConfirmationModal = ({ title, content, isOpen, confirmButton, handleCancel }: Props) => {
  return (
    <Modal isOpen={isOpen} toggle={handleCancel} backdrop='static' size='md'>
      <ModalHeader toggle={handleCancel}>{title}</ModalHeader>
      <ModalBody>{content}</ModalBody>
      <ModalFooter className='pt-0'>
        <div className='text-right'>
          <Button color='link-secondary' onClick={handleCancel} className='mr-3'>
            Cancel
          </Button>
          {confirmButton}
        </div>
      </ModalFooter>
    </Modal>
  );
};
