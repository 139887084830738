import { TableColumn } from '@components/survey/form/input/table/InputInterface';
import {
  hasDataChanged,
  UnitTypeMapValue,
  validateData,
  hasUnitDataChanged,
  InputOverrideType,
  validateUnitData,
  prepareUpdateData,
  UnitConfigType,
} from '@features/question-configuration';
import { useContext, useState } from 'react';
import { Button, ModalBody, ModalFooter } from 'reactstrap';
import { InitiativeUniversalTracker } from '@g17eco/types/initiativeUniversalTracker';
import { InputOverrideGroup } from './InputOverrideGroup';
import { UnitTypes } from '@utils/units';
import { isNumericTableColumnType } from '@utils/universalTracker';
import { cloneDeep } from '@apollo/client/utilities';
import { validateMinMax } from '@utils/universalTrackerValue';
import Loader from '@components/loader';
import { BulkActionUtr } from '@components/survey-question-list/partials/BulkActionToolbar';
import {
  DECIMAL_MAX,
  DECIMAL_MIN,
  DecimalType,
  ErrorDecimalType,
  invalidInputMessage,
  isMultipleUtrUpdate,
} from '@components/utr-decimal/utils';
import { InputOverrideParams } from '@api/initiative-universal-trackers';
import { MultipleInputOverrideGroup } from './MultipleInputOverrideGroup';
import { QuestionManagementContext } from '../../../apps/company-tracker/components/admin-dashboard/questions/QuestionManagementContainer';

interface MetricOverrideFormProps {
  isLoading: boolean;
  tableColumns: TableColumn[];
  selectedQuestions: BulkActionUtr[];
  overriddenQuestions: InitiativeUniversalTracker[];
  handleUpdate: (decimal: InputOverrideParams['decimal'], unitConfig: InputOverrideParams['unitConfig']) => void;
  defaultDecimal: DecimalType;
  defaultUnitConfig: UnitConfigType;
  unitTypeMap: Record<string, Map<string, UnitTypeMapValue>>;
  handleCloseModal: () => void;
}

const defaultState = { data: undefined };

export const MetricOverrideForm = ({
  isLoading,
  selectedQuestions,
  overriddenQuestions,
  tableColumns,
  handleUpdate,
  defaultDecimal,
  defaultUnitConfig,
  unitTypeMap,
  handleCloseModal,
}: MetricOverrideFormProps) => {
  const [decimal, setDecimal] = useState<DecimalType>(defaultDecimal);
  const [unitConfig, setUnitConfig] = useState<UnitConfigType>(defaultUnitConfig);
  const [error, setError] = useState<ErrorDecimalType>(defaultState);
  const { inputOverrideType } = useContext(QuestionManagementContext);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setError((prev) => ({
      ...prev,
      [name]: validateMinMax(value, DECIMAL_MIN, DECIMAL_MAX).errored ? invalidInputMessage : '',
    }));
    setDecimal((prev) => ({
      ...prev,
      [name]: { ...prev[name], value: value === '' ? undefined : parseInt(value) },
    }));
  };

  const getUnitOrNumberScaleValue = ({
    isEnforced,
    unitType,
    code,
  }: {
    isEnforced: boolean;
    unitType: string;
    code: string;
  }) => {
    const utrValue = unitTypeMap[code].get(unitType)?.defaultValue;
    // toggle is off
    if (!isEnforced) {
      return utrValue;
    }
    // toggle is on, default to 'single' if utr's numberScale is undefined
    if (unitType === UnitTypes.numberScale && !utrValue) {
      return 'single';
    }
    return utrValue;
  };

  const toggleDecimalEnforcement = (code: string) => {
    setDecimal((prev) => ({ ...prev, [code]: { value: undefined, isEnforced: !prev[code].isEnforced } }));
    setError((prev) => ({ ...prev, [code]: undefined }));
  };

  const toggleNumberScaleEnforcement = (code: string) => {
    const unitType = UnitTypes.numberScale;
    setUnitConfig((prev) => {
      const isNowEnforced = !prev[code][unitType].isEnforced;
      return {
        ...prev,
        [code]: {
          ...prev[code],
          [unitType]: {
            value: getUnitOrNumberScaleValue({ isEnforced: isNowEnforced, unitType, code }),
            isEnforced: isNowEnforced,
          },
        },
      };
    });
  };

  const toggleUnitEnforcement = (code: string) => {
    setUnitConfig((prev) => {
      const currentUnits = cloneDeep(prev[code]);
      delete currentUnits.numberScale;
      for (const unitType in currentUnits) {
        const isNowEnforced = !currentUnits[unitType].isEnforced;
        currentUnits[unitType].isEnforced = isNowEnforced;
        currentUnits[unitType].value = getUnitOrNumberScaleValue({ isEnforced: isNowEnforced, unitType, code });
      }
      return { ...prev, [code]: { ...prev[code], ...currentUnits } };
    });
  };

  const onToggleSwitch = ({ type, code }: { type: InputOverrideType; code: string }) => {
    switch (type) {
      case InputOverrideType.Decimal:
        return toggleDecimalEnforcement(code);
      case InputOverrideType.NumberScale:
        return toggleNumberScaleEnforcement(code);
      case InputOverrideType.Unit:
        return toggleUnitEnforcement(code);
      default:
        return;
    }
  };

  const isFormValid = validateData<number>({ data: decimal, error }) && validateUnitData({ data: unitConfig });
  const isMultipleUpdate = isMultipleUtrUpdate({ selectedQuestions, overriddenQuestions });
  const hasAnythingChanged =
    hasDataChanged<number>(defaultDecimal, decimal) || hasUnitDataChanged(defaultUnitConfig, unitConfig);
  const isAllowedToUpdate = isFormValid && (hasAnythingChanged || isMultipleUpdate);

  const onClickUpdate = () => {
    if (!isAllowedToUpdate) {
      return;
    }
    const { transformedDecimal, transformedUnitConfig } = prepareUpdateData({ type: inputOverrideType, decimal, unitConfig });
    handleUpdate(transformedDecimal, transformedUnitConfig);
  };

  const onSelectUnit = ({ code, unitType, value }: { code: string; unitType: string; value: string | undefined }) => {
    setUnitConfig((prev) => ({
      ...prev,
      [code]: { ...prev[code], [unitType]: { ...prev[code][unitType], value } },
    }));
  };

  return (
    <>
      <ModalBody className='pt-3 overflow-scroll' style={{ maxHeight: '500px' }}>
        {isLoading ? <Loader /> : null}
        {selectedQuestions.length > 1 ? (
          <MultipleInputOverrideGroup
            hasAnythingChanged={hasAnythingChanged}
            unitTypeMap={unitTypeMap}
            unitConfig={unitConfig}
            decimal={decimal}
            onChange={onChange}
            error={error}
            onSelectUnit={onSelectUnit}
            onToggleSwitch={onToggleSwitch}
          />
        ) : (
          <InputOverrideGroup
            numericColumns={tableColumns.filter((col) => isNumericTableColumnType(col.type))}
            unitTypeMap={unitTypeMap}
            unitConfig={unitConfig}
            decimal={decimal}
            onChange={onChange}
            error={error}
            onSelectUnit={onSelectUnit}
            onToggleSwitch={onToggleSwitch}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button color='transparent' onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button color='primary' disabled={!isAllowedToUpdate || isLoading} onClick={onClickUpdate}>
          Save
        </Button>
      </ModalFooter>
    </>
  );
};
