import { useGetUsedInitiativeIntegrationQuery } from '@api/initiative-integrations';
import Loader from '@components/loader';
import { QuestionInput } from '@components/question/QuestionInput';
import SimpleTooltip from '@components/simple-tooltip';
import { IntegrationConnectionInfo, ProviderIntegrationStatus } from '@g17eco/types/integration';
import { useToggle } from '@hooks/useToggle';
import UniversalTracker from '@models/UniversalTracker';
import { DATE, formatDateUTC } from '@utils/date';
import { useMemo } from 'react';
import { Button, Collapse, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import './IntegrationModal.scss';
import { BasicAlert } from '@components/alert/BasicAlert';
import { UtrvStatus } from '@constants/status';

interface Props {
  code: string | undefined;
  closeModal: () => void;
  handleLinkClick: (props: { link?: string; code: string }) => void;
  initiativeId: string;
}

const ProviderInformation = ({
  provider,
  handleLinkClick,
}: {
  provider: IntegrationConnectionInfo['provider'];
  handleLinkClick: Props['handleLinkClick'];
}) => {
  return (
    <div className='d-flex align-items-center gap-2'>
      <div>
        <img src={provider.logo} width={80} height={80} alt={provider.name} />
      </div>
      <div className='flex-fill'>
        <SimpleTooltip text={provider.name}>
          <h3 className='text-ThemeTextDark text-truncate' style={{ width: '240px' }}>
            {provider.name}
          </h3>
        </SimpleTooltip>
      </div>
      <div>
        <Button color='primary' outline onClick={() => handleLinkClick(provider)}>
          Website
          <i className='ms-2 fal fa-arrow-up-right' />
        </Button>
      </div>
    </div>
  );
};

const ConnectionStatus = ({ connection }: { connection: IntegrationConnectionInfo['connection'] }) => {
  const className = 'text-center';
  switch (connection.status) {
    case ProviderIntegrationStatus.Active:
      return (
        <div className={`${className} text-ThemeSuccessMedium my-3`} style={{ fontSize: '24px' }}>
          <span>Active</span> <i className='fal fa-circle-check' />
        </div>
      );
    default:
      return null;
  }
};

const ConnectionDetails = ({ connection }: { connection: IntegrationConnectionInfo['connection'] }) => {
  return (
    <>
      {[
        ['Set up by:', `${connection.creator.firstName ?? ''} ${connection.creator.surname ?? ''}`.trim()],
        ['Set up initiated:', formatDateUTC(connection.created, DATE.DEFAULT_SPACES)],
      ].map(([label, value]) => (
        <div key={label} className='text-ThemeTextMedium'>
          <span className='text-uppercase text-sm pr-1' style={{ fontWeight: 500 }}>
            {label}
          </span>
          <span className='text-md fw-bold'>{value}</span>
        </div>
      ))}
    </>
  );
};

export const IntegrationModal = ({ code = '', closeModal, handleLinkClick, initiativeId }: Props) => {
  const [isHidden, toggleView] = useToggle(true);
  const { data, isLoading, error } = useGetUsedInitiativeIntegrationQuery({ initiativeId, code }, { skip: !code });
  const { integration, connection, provider } = data ?? {};
  const [firstQuestion] = integration?.requirements.questions ?? [];

  const { utr, utrv } = useMemo(() => {
    if (!firstQuestion || !connection?.data?.answers) {
      return {};
    }
    return {
      utrv: {
        utrCode: firstQuestion.code,
        value: undefined,
        valueData: connection.data?.answers?.[0].valueData,
        status: UtrvStatus.Created,
      },
      utr: new UniversalTracker(firstQuestion as any),
    };
  }, [connection?.data?.answers, firstQuestion]);

  const getModalContent = () => {
    if (isLoading) {
      return <Loader />;
    }

    if (error) {
      return <BasicAlert type='danger'>{'message' in error ? error.message : error}</BasicAlert>;
    }

    if (!provider || !connection) {
      return null;
    }

    return (
      <>
        <h5 className='text-ThemeTextDark'>Connection with:</h5>
        <ProviderInformation provider={provider} handleLinkClick={handleLinkClick} />
        <h5 className='text-ThemeTextDark'>Connection status:</h5>
        <ConnectionStatus connection={connection} />
        <h5 className='text-ThemeTextDark'>Connection details:</h5>
        <ConnectionDetails connection={connection} />

        <div className={`${isHidden ? 'border-bottom' : 'border-top mt-3 pt-3'} border-ThemeBorderDefault`}>
          <Collapse className='question-container' isOpen={!isHidden}>
            {utrv && utr ? <QuestionInput utrv={utrv} utr={utr} isDisabled={() => true} /> : null}
          </Collapse>
          <div className='text-center my-3'>
            <Button color='transparent' onClick={() => toggleView()}>
              {`View ${isHidden ? 'more' : 'less'}`}
              <i className={`text-md ml-1 fal ${isHidden ? 'fa-angle-down' : 'fa-angle-up'}`} />
            </Button>
          </div>
        </div>
      </>
    );
  };

  return (
    <Modal isOpen={!!code} toggle={closeModal} backdrop='static' className='integration-modal-container'>
      <ModalHeader toggle={closeModal}>Connection details</ModalHeader>
      <ModalBody>{getModalContent()}</ModalBody>
      <ModalFooter className='pt-0'>
        <Button color='transparent' onClick={closeModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
