import {
  InputOverrideParams,
  useGetInitiativeUniversalTrackersQuery,
  useUpdateInitiativeUniversalTrackerMutation,
} from '@api/initiative-universal-trackers';
import { useSiteAlert } from '@hooks/useSiteAlert';
import { isDefined } from '@utils/index';
import { isNumericTableColumnType } from '@utils/universalTracker';
import { BulkActionUtr } from '@components/survey-question-list/partials/BulkActionToolbar';
import { MetricOverrideForm } from './MetricOverrideForm';
import { getInitialState, getInputType, isSingleTableQuestion } from '@components/utr-decimal/utils';
import { InitiativeUniversalTracker } from '@g17eco/types/initiativeUniversalTracker';
import { getInitialUnitConfig, getUnitTypeMap } from '../utils';

export interface MetricOverrideContainerProps {
  handleReload: (props?: { reloadSurvey?: boolean, closeModal?: boolean }) => Promise<void | undefined>;
  selectedQuestions: BulkActionUtr[];
  numericSelectedQuestions: BulkActionUtr[];
  initiativeId: string;
  handleCloseModal: () => void;
}

const hasOverriddenProperty = (utr: InitiativeUniversalTracker, type: 'decimal' | 'unitInput' | 'numberScaleInput') => {
  if (type === 'decimal') {
    return (
      isDefined(utr.valueValidation?.[type]) ||
      utr.valueValidation?.table?.columns.some((col) => isDefined(col.validation?.[type]))
    );
  }
  return (
    isDefined(utr[type]) ||
    utr.valueValidation?.table?.columns.some((col) => isDefined(col[type]))
  );
};

export const MetricOverrideContainer = (props: MetricOverrideContainerProps) => {
  const { handleReload, selectedQuestions, initiativeId, numericSelectedQuestions, handleCloseModal } = props;
  const selectedUtrIds = selectedQuestions.map((q) => q._id);
  const { addSiteError } = useSiteAlert();

  const { data: initiativeUtrs = [], isFetching: isInitiativeUtrsLoading } = useGetInitiativeUniversalTrackersQuery(
    initiativeId,
    {
      skip: !initiativeId || selectedQuestions.length === 0,
    }
  );

  const [updateInitiativeUtrs, { isLoading: isUpdating }] = useUpdateInitiativeUniversalTrackerMutation();

  const { overriddenQuestions, firstDecimalValidation } = initiativeUtrs.reduce(
    (acc, utr) => {
      if (!selectedUtrIds.includes(utr.universalTrackerId)) {
        return acc;
      }

      const hasOverriddenDecimal = hasOverriddenProperty(utr, 'decimal');
      const hasOverriddenUnit = hasOverriddenProperty(utr, 'unitInput') || hasOverriddenProperty(utr, 'numberScaleInput');

      if (!hasOverriddenDecimal && !hasOverriddenUnit) {
        return acc;
      }

      if (hasOverriddenDecimal && !acc.firstDecimalValidation) {
        acc.firstDecimalValidation = utr;
      }
      acc.overriddenQuestions.push(utr);
      return acc;
    },
    { overriddenQuestions: [], firstDecimalValidation: undefined } as {
      overriddenQuestions: InitiativeUniversalTracker[];
      firstDecimalValidation: InitiativeUniversalTracker | undefined;
    }
  );

  const tableColumns = isSingleTableQuestion(numericSelectedQuestions)
    ? numericSelectedQuestions[0].valueValidation?.table?.columns?.filter((c) => isNumericTableColumnType(c.type)) || []
    : [];

  const inputType = getInputType(numericSelectedQuestions);

  const defaultDecimal = getInitialState({
    inputType,
    tableColumns,
    valueValidation: firstDecimalValidation?.valueValidation,
  });

  const unitTypeMap = getUnitTypeMap({ selectedQuestions, overriddenQuestions, inputType });
  const defaultUnitConfig = getInitialUnitConfig({ inputType, tableColumns, unitTypeMap });

  const handleUpdate = (decimal: InputOverrideParams['decimal'], unitConfig: InputOverrideParams['unitConfig']) => {
    const data = {
      decimal,
      unitConfig,
      initiativeId,
      utrIds: numericSelectedQuestions.map((q) => q._id),
    };
    updateInitiativeUtrs(data)
      .unwrap()
      .then(() => {
        handleReload({ reloadSurvey: true, closeModal: selectedQuestions.length === 1 });
      })
      .catch((err) => {
        addSiteError(err);
        handleReload({ closeModal: true });
      });
  };

  return (
    <MetricOverrideForm
      isLoading={[isInitiativeUtrsLoading, isUpdating].some((loading) => loading)}
      selectedQuestions={selectedQuestions}
      tableColumns={tableColumns}
      handleUpdate={handleUpdate}
      overriddenQuestions={overriddenQuestions}
      defaultDecimal={defaultDecimal}
      defaultUnitConfig={defaultUnitConfig}
      unitTypeMap={unitTypeMap}
      handleCloseModal={handleCloseModal}
    />
  );
};
