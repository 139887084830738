import { useState } from 'react';
import { SDGTargetDropdown } from './SDGTargetDropdown';
import { InsightDashboardItemType } from '@g17eco/types/insight-custom-dashboard';
import { MetricSelectorProps } from '../types';
import { QuestionData } from '@routes/custom-dashboard/utils';
import { emptyUtrData, isValidUtrVariable } from '../utils/dashboard-utils';
import { QuestionSelectingFilter } from '@features/custom-dashboard';
import { Button } from 'reactstrap';
import { IntegrationSourceSelector } from '@routes/custom-dashboard/items/integrations/IntegrationSourceSelector';
import { SURVEY } from '@constants/terminology';

interface Props extends MetricSelectorProps {
  togglePreviewModal: () => void;
  helpText: JSX.Element;
}

export const SingleMetricSelector = (props: Props) => {
  const { chartData, changeChartData, initiativeId, togglePreviewModal, helpText } = props;

  const [questionData, setQuestionData] = useState<QuestionData>(chartData.metrics?.[0] ?? emptyUtrData);

  const handleSelect = (utr: QuestionData) => {
    setQuestionData(utr);
    if (isValidUtrVariable(utr)) {
      changeChartData({ ...chartData, metrics: [utr] });
    }
  };

  const getContent = () => {
    switch (chartData.type) {
      case InsightDashboardItemType.Chart:
        return (
          <>
            <p className='mt-3 text-medium'>Select data-point from {SURVEY.SINGULAR} scope</p>
            <QuestionSelectingFilter
              questionData={questionData}
              initiativeId={initiativeId}
              handleSelect={handleSelect}
            />
          </>
        );
      case InsightDashboardItemType.Integration:
        return <IntegrationSourceSelector questionData={questionData} changeQuestionData={handleSelect} />;
      case InsightDashboardItemType.SDGTracker:
        return <SDGTargetDropdown questionData={questionData} changeQuestionData={handleSelect} />;
      default:
        return null;
    }
  };

  const shouldShowPreview = chartData.metrics?.some((m) => m.code);
  return (
    <>
      {getContent()}
      {shouldShowPreview ? (
        <div className='d-flex mt-3 justify-content-between'>
          {helpText}
          <Button color='secondary' onClick={togglePreviewModal}>
            <i className='fal fa-eye mr-2' />
            Preview
          </Button>
        </div>
      ) : (
        <div className='mt-1'>{helpText}</div>
      )}
    </>
  );
};
