import { getMetricUnit, getTextColor } from './utils';
import { ChartSubType, GridDashboardChartItem } from '@g17eco/types/insight-custom-dashboard';
import {
  getChartColors,
  getChartDataByValues,
  getChartMaxValue,
  getValueWithFallback,
  sortUtrvByDate,
} from '@utils/charts';
import { isNumeric } from '@utils/number';
import NumberFormat from '@utils/number-format';
import { Note } from '@routes/custom-dashboard/items/charts/common/Note';
import Chart from 'react-google-charts';
import { ChartType, UtrVariable } from '@routes/summary/insights/utils/constants';
import { getSparklineChartProps } from '@routes/summary/insights/utils/helpers';
import { TakenFromText } from '@routes/custom-dashboard/items/charts/common/TakenFromText';
import { UtrvType } from '@constants/status';
import { HistoricalGenerated, IntegrationData } from '@g17eco/types/integration';
import { getChartOptions } from '@routes/summary/insights/utils/chartStyles';
import { DATE } from '@utils/date';

const MIDDLE_OF_MONTH = 15;

type Props = {
  integrationData: IntegrationData | undefined;
  hasSparkLine?: boolean;
  item: Pick<GridDashboardChartItem, 'calculation' | 'unitText' | 'variables' | 'note' | 'subType'>;
};

interface LatestValueData {
  utrsData: HistoricalGenerated[];
  variables: Record<string, UtrVariable>;
}

const getLatestValueData = ({ utrsData, variables }: LatestValueData) => {
  const [variable] = Object.values(variables);
  const utrData = variable ? utrsData.find((data) => data.utr.code === variable.code) : undefined;

  if (!utrData) {
    return {
      variablesWithValues: {},
    };
  }

  const chartData = getChartDataByValues(utrData.utrvs, variable.valueListCode);

  const sortedUtrvs = utrData.utrvs.slice().sort(sortUtrvByDate).reverse();
  const latestActualUtrv = sortedUtrvs.find((utrv) => utrv.type === UtrvType.Actual);

  const variablesWithValues = {
    [variable.code]: latestActualUtrv
      ? getValueWithFallback(latestActualUtrv, variable.valueListCode, { isMultiRowsTable: false, fallback: '' })
      : '',
  };

  return {
    actualValue: variablesWithValues[variable.code] || '',
    period: latestActualUtrv?.period || '',
    effectiveDate: latestActualUtrv?.effectiveDate || '',
    variablesWithValues,
    chartData,
  };
};

export const SingleValueIntegration = (props: Props) => {
  const { integrationData, item, hasSparkLine = false } = props;

  const { calculation, unitText, variables, note } = item;

  if (!integrationData?.utrsData.length || !variables) {
    return null;
  }

  const { utrsData } = integrationData;

  const metricUnit = unitText || getMetricUnit(integrationData.utrsData);
  const isSuffix = metricUnit === '%';
  const decimalPlaces = calculation?.values[0].decimalPlaces ?? 3;

  const latestValueData = getLatestValueData({ utrsData, variables });
  const { actualValue, effectiveDate, period, variablesWithValues, chartData } = latestValueData;

  const textColor = getTextColor({ actualValue, targetValue: undefined });

  if (item.subType === ChartSubType.Line) {
    const ticks = chartData?.slice(1).map((data) => {
      const newDate = new Date(data[0] as Date);
      // Middle of month to avoid drawing 2 ticks that are too close to each other
      newDate.setDate(MIDDLE_OF_MONTH);
      return newDate;
    });

    const vAxisMaxValue = getChartMaxValue(chartData);

    return (
      <div className='w-100 h-100'>
        <Chart
          chartType={ChartType.LineChart}
          data={chartData}
          width={'100%'}
          height={'100%'}
          options={{
            ...getChartOptions({
              chartType: ChartType.LineChart,
              vAxisTitle: metricUnit,
              vAxisMaxValue,
            }),
            interpolateNulls: true,
            pointSize: 5,
            colors: getChartColors(false),
            hAxis: {
              format: DATE.MONTH_YEAR_SHORT,
              ticks: ticks,
              slantedText: true,
            },
          }}
        />
      </div>
    );
  }

  return (
    <div className='w-100 h-100 d-flex flex-column justify-content-center'>
      <div className='flex-fill d-flex flex-column justify-content-center'>
        {isNumeric(actualValue) ? (
          <NumberFormat
            className={`h1 m-0 text-center ${textColor}`}
            value={actualValue}
            prefix={isSuffix ? '' : metricUnit}
            suffix={isSuffix ? metricUnit : ''}
            decimalPlaces={decimalPlaces}
          />
        ) : (
          <div className='h1 m-0 text-center text-ThemeHeadingLight'>-</div>
        )}
        {note ? (
          <Note note={note} variablesWithValues={variablesWithValues} className='text-center text-ThemeTextLight' />
        ) : null}
      </div>
      {hasSparkLine ? (
        <div style={{ height: '40%' }}>
          <Chart
            height={'100%'}
            chartType={ChartType.LineChart}
            data={chartData}
            options={getSparklineChartProps({ isRestated: false })}
          />
        </div>
      ) : null}
      <TakenFromText effectiveDate={effectiveDate} period={period} />
    </div>
  );
};
