import { useEffect, RefObject } from 'react';

// Hook to detect click outside of a component
export const useClickOutside = (ref: RefObject<HTMLElement>, callback?: () => void) => {
  useEffect(() => {
    if (!callback) {
      return;
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Cleanup event listener on component unmount
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
};
