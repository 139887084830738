import { CollapseContent, CollapsePanel } from '@components/collapse-panel';
import { useToggle } from '@hooks/useToggle';
import { Button } from 'reactstrap';
import { useGetInitiativeUniversalTrackersQuery } from '@api/initiative-universal-trackers';
import { getUtrFromSurvey } from '@selectors/survey';
import { SurveyActionData } from '@models/surveyData';
import { BaseQuestionGroup } from '@g17eco/types/survey';
import { ScopeQuestion } from '@g17eco/types/surveyScope';
import { AggregatedBreakdownDetail } from './AggregatedBreakdownDetail';
import { useGetDisaggregationByUtrvIdQuery } from '@api/disaggregation';
import Loader from '@components/loader';
import { BasicAlert } from '@components/alert/BasicAlert';
import { FeatureStability } from '@g17eco/molecules';
import { SURVEY } from '@constants/terminology';

interface Props {
  initiativeId: string;
  survey: SurveyActionData;
  surveyGroups: BaseQuestionGroup<ScopeQuestion>[];
  questionId: string;
}

export const AggregatedQuestionBreakdown = (props: Props) => {
  const { initiativeId, survey, surveyGroups, questionId } = props;
  const [isOpen, toggle] = useToggle(false);

  const { isLoading: isInitiativeUtrLoading } = useGetInitiativeUniversalTrackersQuery(initiativeId, {
    skip: !initiativeId,
  });
  const { utr, utrv } = getUtrFromSurvey({ survey, surveyGroups, questionId });

  const { data, isLoading, error } = useGetDisaggregationByUtrvIdQuery(utrv?._id ?? '', {
    skip: !isOpen || !utrv?._id,
  });

  return (
    <>
      <div className='px-3 d-flex'>
        <p className='m-0'>
          This answer has been generated using aggregated data from existing {SURVEY.PLURAL} and therefor cannot be modified. To
          view or update this metric please modify the underlying datapoints.
        </p>
        <Button className='flex-shrink-0 px-3' color='link-secondary' onClick={toggle}>
          <>
            View aggregated data
            {isOpen ? <i className='fal fa-caret-up ml-2' /> : <i className='fal fa-caret-down ml-2' />}
            <FeatureStability key={'internal'} stability={'internal'} />
          </>
        </Button>
      </div>
      <CollapsePanel collapsed={!isOpen}>
        <CollapseContent className='px-3'>
          <div className='border-top my-3' />
          {error ? (
            <BasicAlert key={error.name} type={'danger'}>
              {error.message}
            </BasicAlert>
          ) : null}
          {isLoading || isInitiativeUtrLoading ? <Loader /> : <AggregatedBreakdownDetail utr={utr} utrv={utrv} data={data} />}
        </CollapseContent>
      </CollapsePanel>
    </>
  );
};
