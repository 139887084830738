import { DashboardRow } from '@components/dashboard';
import { SelectFactory, SelectTypes } from '@g17eco/molecules';

export enum DelegationOption {
  Anyone = 'anyone',
  Me = 'me',
  ExcludeMe = 'exclude-me',
}

export enum ReportOption {
  ExcludeDeleted = 'exclude-deleted-report',
  IncludeDeleted = 'include-deleted-report',
}

export enum StatusOption {
  All = 'all',
  Assured = 'assured',
  InProgress = 'in-progress',
}

const delegationSelectOptions = [
  { label: 'Assigned to anyone', value: DelegationOption.Anyone },
  { label: 'Assigned to me', value: DelegationOption.Me },
  { label: 'Not assigned to me', value: DelegationOption.ExcludeMe },
];

const reportSelectOptions = [
  { label: 'Exclude deleted reports', value: ReportOption.ExcludeDeleted },
  { label: 'Include deleted reports', value: ReportOption.IncludeDeleted },
];

const statusSelectOptions = [
  { label: 'All report statuses', value: StatusOption.All },
  { label: 'Assured reports only', value: StatusOption.Assured },
  { label: 'Assurance in progress', value: StatusOption.InProgress },
];

export interface Filters {
  delegation: DelegationOption;
  report: ReportOption;
  status: StatusOption;
}

interface Props {
  filters: Filters;
  handleSetFilters: (props: Partial<Filters>) => void;
}

const getSelectOptions = (type: string) => {
  switch (type) {
    case 'delegation':
      return delegationSelectOptions;
    case 'report':
      return reportSelectOptions;
    case 'status':
      return statusSelectOptions;
    default:
      return [];
  }
};

export const AssuranceListFilters = (props: Props) => {
  const { filters, handleSetFilters } = props;

  return (
    <DashboardRow>
      <div className='w-100 d-flex gap-2 flex-column flex-md-row'>
        {Object.keys(filters).map((type) => {
          const options = getSelectOptions(type);

          return (
            <SelectFactory<string>
              key={type}
              className='flex-grow-1'
              selectType={SelectTypes.SingleSelect}
              options={options}
              value={options.find((op) => op.value === filters[type as keyof Filters]) ?? null}
              onChange={(op) => (op ? handleSetFilters({ [type]: op.value }) : undefined)}
              isSearchable={false}
            />
          );
        })}
      </div>
    </DashboardRow>
  );
};
