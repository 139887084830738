import { Country } from './common';

export enum BankType {
  AbsBank = 'AbsBank',
  ManualBank = 'ManualBank'
}

export interface Bank {
  name?: string;
  code: string;
  licenceType?: string;
  logo?: string;
  type?: BankType;
  countryCodes?: string[];
  popularCountryCodes?: string[];
  countries?: Country[];
  popularCountries?: Country[];
}

export type BankingSetting = Pick<Bank, 'name' | 'code' | 'type'>;

export enum BankingAction {
  Add = 'add',
  Remove = 'remove'
}

export interface UpdateBankingSetting {
  bankingSettings: BankingSetting[];
  updateBank?: Bank;
  action?: BankingAction;
}