import { useState } from 'react';
import { bytesToHumanReadable } from '@utils/index';
import { DATE, formatDateNonUTC } from '@utils/date';
import classNames from 'classnames';
import { ImportJobPlain, TaskType, UploadFileTask, ValidationError } from '@g17eco/types/background-jobs';
import { ImportIssuesModal } from './ImportIssuesModal';
import Loader from '../../loader';
import { ImportActions, isProcessingImportData } from './ImportActions';

interface BulkImportJobsListProps {
  bulkImportJobs: ImportJobPlain[];
  isLoading: boolean;
  runBulkImportJob: (jobId: string) => void;
  deleteBulkImportJob: (jobId: string) => void;
}

export const BulkImportJobsList = ({
  bulkImportJobs,
  isLoading,
  runBulkImportJob,
  deleteBulkImportJob,
}: BulkImportJobsListProps) => {
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);

  if (bulkImportJobs.length === 0) {
    return (
      <div className='text-center text-md mt-4 text-ThemeTextMedium'>
        There are no outstanding files requiring import.
      </div>
    );
  }

  return (
    <>
      {isLoading ? <Loader relative /> : null}
      <div className='d-flex flex-column gap-2'>
        {bulkImportJobs.map((importJob) => {
          const { _id, tasks, updated } = importJob;
          const latestTask = tasks[tasks.length - 1];
          if (!latestTask) {
            return null;
          }

          const uploadFileTask = tasks.find((t) => t.type === TaskType.UploadFile) as UploadFileTask;
          return (
            <div
              key={_id}
              className={classNames(
                'w-100 position-relative d-flex justify-content-between align-items-center p-3 border border-ThemeBorderDefault rounded',
                { 'processing-import-data': isProcessingImportData(importJob, latestTask) }
              )}
            >
              <div className='flex-grow-1 text-md import-content'>
                <div className='strong'>{uploadFileTask?.data?.file?.name}</div>
                <div className='text-ThemeTextMedium'>{bytesToHumanReadable(uploadFileTask?.data?.file?.size)}</div>
                <div className='text-ThemeTextMedium'>{formatDateNonUTC(updated, DATE.UK)}</div>
              </div>
              <ImportActions
                job={importJob}
                latestTask={latestTask}
                isLoading={isLoading}
                runBulkImportJob={runBulkImportJob}
                deleteBulkImportJob={deleteBulkImportJob}
                handleShowIssues={(errors) => setValidationErrors(errors)}
              />
            </div>
          );
        })}
      </div>
      <div className='text-center text-md mt-4 text-ThemeTextMedium'>
        Pending files will be automatically deleted after 24 hours.
      </div>
      {validationErrors.length > 0 ? (
        <ImportIssuesModal validationErrors={validationErrors} toggle={() => setValidationErrors([])} />
      ) : null}
    </>
  );
};
