import { InputOverrideType } from '@features/question-configuration';
import React, { useState } from 'react';

interface QuestionManagementContextProps {
  inputOverrideType: InputOverrideType | undefined;
  setInputOverrideType: (type: InputOverrideType | undefined) => void;
}

const initialState: QuestionManagementContextProps = {
  inputOverrideType: undefined,
  setInputOverrideType: () => {},
};

export const QuestionManagementContext = React.createContext(initialState);

export default function QuestionManagementContainer({ children }: { children: any }) {
  const [inputOverrideType, setInputOverrideType] = useState<InputOverrideType | undefined>(initialState.inputOverrideType);
  const contextValue: QuestionManagementContextProps = { inputOverrideType, setInputOverrideType };
  return <QuestionManagementContext.Provider value={contextValue}>{children}</QuestionManagementContext.Provider>;
}
