import { isMediaType, isTableType, isTextOrHeadlineType } from '@routes/custom-dashboard/utils';
import { isChartItem, ItemEditorProps } from '../types';
import { ChartEditor } from './ChartEditor';
import { MediaEditor } from './media/MediaEditor';
import { TableEditor } from './table/TableEditor';
import { TextEditor } from './text/TextEditor';

export const DashboardItemEditor = (props: ItemEditorProps) => {
  const { editingItem, initiativeId, queryParams, updateItem, survey, handleCancel, integrationsData } = props;

  if (!editingItem) {
    return null;
  }

  if (isTextOrHeadlineType(editingItem)) {
    return (
      <TextEditor
        initiativeId={initiativeId}
        editingItem={editingItem}
        updateItem={updateItem}
        handleCancel={handleCancel}
      />
    );
  }

  if (isChartItem(editingItem)) {
    return (
      <ChartEditor
        initiativeId={initiativeId}
        editingItem={editingItem}
        updateItem={updateItem}
        queryParams={queryParams}
        survey={survey}
        handleCancel={handleCancel}
        integrationsData={integrationsData}
      />
    );
  }

  if (isTableType(editingItem)) {
    return (
      <TableEditor
        initiativeId={initiativeId}
        editingItem={editingItem}
        updateItem={updateItem}
        handleCancel={handleCancel}
        queryParams={queryParams}
      />
    );
  }

  if (isMediaType(editingItem)) {
    return <MediaEditor editingItem={editingItem} updateItem={updateItem} handleCancel={handleCancel} />;
  }

  return null;
};
