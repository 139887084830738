import { matchPath, useLocation } from 'react-router-dom';
import Select, {
  components,
  GroupBase,
  SingleValue,
  ValueContainerProps,
  StylesConfig,
  ControlProps,
  CSSObjectWithLabel,
  OptionProps,
  SingleValueProps,
} from 'react-select';
import { NavSelectOption } from '.';
import variables from '../../../css/variables.module.scss';

interface NavSelectProps {
  renderedRoutes: NavSelectOption[];
  onSelectRoute: (route: SingleValue<NavSelectOption>) => void;
  isOptionDisabled: (route: NavSelectOption) => boolean;
}

type IsNotMulti = false;

const ValueContainer = ({
  children,
  ...props
}: ValueContainerProps<NavSelectOption, false, GroupBase<NavSelectOption>>) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!children && <i className='fas fa-bars mr-2 fa-2x' aria-hidden='true' style={{ position: 'relative' }} />}
        {children}
      </components.ValueContainer>
    )
  );
};

export const NavSelect = (props: NavSelectProps) => {
  const { renderedRoutes, onSelectRoute, isOptionDisabled } = props;
  const location = useLocation();

  const selectedRoute = renderedRoutes.find((route) => !!matchPath(location.pathname, route));

  const customStyles: StylesConfig<NavSelectOption, IsNotMulti> = {
    container: (provided: CSSObjectWithLabel) => ({
      ...provided,
      alignSelf: 'center',
    }),
    control: (
      provided: CSSObjectWithLabel,
      state: ControlProps<NavSelectOption, IsNotMulti, GroupBase<NavSelectOption>>
    ) => ({
      ...provided,
      border: 'none',
      boxShadow: 'none',
      backgroundColor: state.selectProps.menuIsOpen ? variables.AccentMedium : variables.TextWhite,
      '&:hover': {
        backgroundColor: variables.AccentMedium,
        '[class*="-singleValue"], i': {
          color: variables.TextWhite,
          transition: 'color 100ms ease-in-out',
        },
      },
    }),
    option: (
      provided: CSSObjectWithLabel,
      state: OptionProps<NavSelectOption, IsNotMulti, GroupBase<NavSelectOption>>
    ) => {
      const getColor = () => {
        if (state.isDisabled) {
          return variables.BgDisabled;
        }
        return state.isSelected ? variables.AccentMedium : 'inherit';
      };

      const hoverColor = () => {
        if (state.isDisabled) {
          return {
            color: variables.BgDisabled,
            backgroundColor: 'transparent',
          };
        }
        return {
          borderLeft: `3px solid ${variables.AccentMedium}`,
          color: variables.AccentMedium,
          backgroundColor: 'transparent',
          transition: 'color 100ms ease-in-out',
        };
      }

      return {
        ...provided,
        borderLeft: state.isSelected ? `3px solid ${variables.AccentMedium}` : '3px solid transparent',
        color: getColor(),
        backgroundColor: 'transparent',
        '&:hover': hoverColor(),
      };
    },
    indicatorsContainer: (provided: CSSObjectWithLabel) => ({
      ...provided,
      display: 'none',
    }),
    valueContainer: (
      provided: CSSObjectWithLabel,
      state: ValueContainerProps<NavSelectOption, IsNotMulti, GroupBase<NavSelectOption>>
    ) => ({
      ...provided,
      display: 'flex',
      padding: '2px 10px',
      color: state.selectProps.menuIsOpen ? variables.TextWhite : variables.AccentMedium,
    }),
    singleValue: (
      provided: CSSObjectWithLabel,
      state: SingleValueProps<NavSelectOption, IsNotMulti, GroupBase<NavSelectOption>>
    ) => {
      return {
        ...provided,
        color: state.selectProps.menuIsOpen ? variables.TextWhite : variables.AccentMedium,
      };
    },
    menu: (provided: CSSObjectWithLabel) => ({
      ...provided,
      marginTop: 0,
    }),
  };

  return (
    <Select
      placeholder={'Select setting'}
      className='w-20'
      isMulti={false}
      styles={customStyles}
      onChange={onSelectRoute}
      value={selectedRoute ?? null}
      isSearchable={false}
      components={{ ValueContainer }}
      options={renderedRoutes}
      isOptionDisabled={isOptionDisabled}
    />
  );
};
