import { useEffect, useState } from 'react';
import { SurveySettings } from '@g17eco/types/survey';
import { DisableUtrsProps, generateDisabledUtrs } from '@components/survey/utils/getDisableUtrs';
import { useAppSelector } from '@reducers/index';
import { getCurrentUser } from '@selectors/user';
import { SurveyActionData } from '@models/surveyData';
import { generateSurveyQuestionsFlexSearchMap } from '@selectors/blueprint';
import { useDispatch } from 'react-redux';
import { loadScorecardsByInitiativeId } from '@actions/scorecard';
import { loadDelegationUsers } from '../../../slice/surveyDelegationUsers';
import { useGetCustomTagsQuery } from '@api/metric-groups';
import { useGetBlueprintsQuery } from '@api/blueprints';
import { skipToken } from '@reduxjs/toolkit/dist/query';

const initialFilters = {
  filterByStatus: [],
  filterByDelegationStatus: [],
  filterByMateriality: [],
  filterByGoal: [],
  filterByStandard: [],
  filterByFramework: [],
  filterByTag: [],
  searchText: '',
};

type Props = Pick<DisableUtrsProps, 'surveyGroups' | 'materiality'> & { survey?: SurveyActionData };

export const useFilterQuestions = ({ surveyGroups, survey, materiality }: Props) => {
  const user = useAppSelector(getCurrentUser);
  const dispatch = useDispatch();
  const { data: tags = [] } = useGetCustomTagsQuery(survey?.initiativeId ?? '', { skip: !survey?.initiativeId });
  const { data: blueprint } = useGetBlueprintsQuery(survey?.sourceName ?? skipToken);

  const searchIndex = generateSurveyQuestionsFlexSearchMap(survey);

  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    if (!survey?._id || !survey?.initiativeId) {
      return;
    }

    dispatch(loadScorecardsByInitiativeId(survey.initiativeId));
    dispatch(loadDelegationUsers(survey._id));
  }, [survey?.initiativeId, survey?._id, dispatch]);

  const changeFilter = (key: keyof SurveySettings, setting: any) => {
    setFilters((filters) => {
      return { ...filters, [key]: setting };
    });
  };

  const {
    filterByGoal,
    searchText,
    filterByStatus,
    filterByStandard,
    filterByFramework,
    filterByMateriality,
    filterByDelegationStatus,
    filterByTag,
  } = filters;

  const disabledUTRs = generateDisabledUtrs({
    surveyGroups,
    blueprint,
    materiality,
    filterByGoal,
    searchText,
    filterByStatus,
    filterByStandard,
    filterByFramework,
    filterByMateriality,
    filterByDelegationStatus,
    filterByTag,
    searchIndex,
    user,
    tags,
  });

  return { filters, changeFilter, disabledUTRs };
};
