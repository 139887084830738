import { useState } from 'react';
import { Button, Input, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Tag } from '../../types/metricGroup';
import { onlyLettersAndNumbers } from '../../utils/string';
import { BasicAlert } from '../alert/BasicAlert';
import { DeleteModal } from '@g17eco/molecules';
import { Message } from './TagContainer';
import { TagList } from './TagList';
import { QUESTION } from '@constants/terminology';

interface ManageTagProps {
  initiativeId: Tag['initiativeId'];
  tags: Tag[];
  toggleManageTag: () => void;
  handleAddTag: (name: Tag['groupName']) => void;
  handleDeleteTags: (ids: Tag['_id'][]) => void;
  message: Message | undefined;
}

const backButtonId = 'back-button';

const deleteModalProps = {
  title: 'tag',
  content: (
    <>
      Are you sure you want to delete the tag? <br /> It will be removed from everywhere - including all the {QUESTION.PLURAL} it was attached to.
    </>
  ),
};

export const ManageTags = (props: ManageTagProps) => {
  const { tags, initiativeId, message, toggleManageTag, handleAddTag, handleDeleteTags } = props;

  const [adding, setAdding] = useState(false);
  const [tag, setTag] = useState<Tag['groupName']>('');
  const [selectedTagIds, setSelectedTagIds] = useState<Tag['_id'][]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [alert, setAlert] = useState<string>('');

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTag(e.target.value);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter' || e.shiftKey || !tag) {
      return;
    }
    handleCreateTag();
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.relatedTarget?.id === backButtonId) {
      return;
    }
    handleCreateTag();
  };

  const isDuplicatedTagName = (tag: Tag['groupName']) => {
    return tags.some((group) => group.groupName === tag);
  };

  const handleCreateTag = async () => {
    if (!tag || !initiativeId) {
      handleResetInput();
      return;
    }
    if (!onlyLettersAndNumbers(tag)) {
      return;
    }

    if (isDuplicatedTagName(tag)) {
      setAlert('This tag already exists');
      return;
    }

    await handleAddTag(tag);
    handleResetInput();
  };

  const handleResetInput = () => {
    setTag('');
    setAlert('');
    setAdding(false);
  };

  const handleSelect = (selectedTag: Tag) => {
    const selectedTagId = selectedTag._id;
    const tagIds = selectedTagIds.includes(selectedTagId)
      ? selectedTagIds.filter((tagId) => tagId !== selectedTagId)
      : [...selectedTagIds, selectedTagId];
    setSelectedTagIds(tagIds);
  };

  const handleDelete = () => {
    setOpenDeleteModal(false);
    handleDeleteTags(selectedTagIds);
    setSelectedTagIds([]);
  };

  const alertMessage = !onlyLettersAndNumbers(tag) ? 'Tags are limited to letters and numbers only' : alert;

  return (
    <>
      <ModalBody>
        {
          <Button id={backButtonId} color='link' onClick={toggleManageTag}>
            <i className='fa fa-chevron-left mr-2 mb-2' />
            Back to tags
          </Button>
        }
        {message ? (
          <BasicAlert className='mb-2 p-2 text-sm' type={message.type}>
            {message.content}
          </BasicAlert>
        ) : null}
        {adding ? (
          <>
            <div className='d-flex align-items-center'>
              <i className='fal fa-tag text-ThemeIconSecondary mr-2'></i>
              <Input
                onChange={onChange}
                value={tag}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                placeholder='Tag name'
                autoFocus
                data-testid='tag-name-input'
              />
            </div>
            <BasicAlert className='mt-3 p-2 text-sm' type={'danger'} hide={!alertMessage}>
              {alertMessage}
            </BasicAlert>
          </>
        ) : null}
        <TagList tags={tags} handleSelect={handleSelect} />
      </ModalBody>
      <ModalFooter className='d-flex justify-content-between'>
        <Button color='transparent' className='d-flex align-items-center' onClick={() => setAdding(true)} data-testid='create-tag-btn'>
          <i className='fal fa-plus mr-2 text-sm'></i> Create tag
        </Button>
        <Button color='danger' outline disabled={!selectedTagIds.length} onClick={() => setOpenDeleteModal(true)} data-testid='delete-tag-btn'>
          Delete
        </Button>
      </ModalFooter>

      <DeleteModal
        {...deleteModalProps}
        isOpen={openDeleteModal}
        handleDelete={handleDelete}
        handleCancel={() => setOpenDeleteModal(false)}
      />
    </>
  );
};
