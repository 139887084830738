import { TimeRangeSelectorProps } from '@g17eco/molecules';
import { PeriodDropdownProps } from '@components/survey-period-dropdown';
import { UtrVariable } from '../summary/insights/utils/constants';
import { DashboardSurveyType } from '@g17eco/types/insight-custom-dashboard';

export interface ExtendedUtrVariable extends UtrVariable {
  name?: string;
}

export type DataFilters = Pick<PeriodDropdownProps, 'period' | 'setPeriod'> &
  Pick<TimeRangeSelectorProps, 'dateRange' | 'timeRange' | 'onChangeDateRange'> & { surveyType?: DashboardSurveyType };

// TODO: Remove this after finishing testing
export enum ExperimentalOption {
  SpaceWidget = 'space-widget',
  PreventCollision = 'prevent-collision',
  Default = 'default',
}

export type ExperimentalPropsMap = {
  [key in ExperimentalOption]: {
    label: string;
    props: {
      enableSpaceWidget?: boolean;
      preventCollision?: boolean;
      compactType?: 'vertical' | 'horizontal' | null | undefined;
    };
  };
}