import { DashboardDivider } from '@g17eco/atoms';
import { NumberAnswer, TextAnswer } from './BaseTypeAnswers';
import { UtrValueType } from '@g17eco/types/universalTracker';
import { SUPPORTED_UTR_TYPES } from '../constants';
import { PreviousAnswers } from '../PreviousAnswers';
import { AnswerDataProps } from '../Answer';

interface SingleInputAnswerProps extends Omit<AnswerDataProps, 'predictedData' | 'table'> {
  predictedAnswer: string | number;
}

export const SingleInputAnswerData = ({ utr, utrv, ...props }: Omit<SingleInputAnswerProps, 'previousUtrvs'>) => {
  switch (utr.valueType) {
    case UtrValueType.Text: {
      const inputAnswer = utrv.valueData?.data as string;
      return <TextAnswer inputAnswer={inputAnswer} {...props} />;
    }
    case UtrValueType.Number:
    case UtrValueType.Percentage: {
      const inputAnswer = utrv.value;
      return <NumberAnswer utr={utr} inputAnswer={inputAnswer} {...props} />;
    }
    default:
      return null;
  }
};

export const SingleInputAnswer = (props: SingleInputAnswerProps) => {
  const { utr, utrv, hasPreviousUtrvs, predictedAnswer, isFetching, targetValue, previousUtrvs } = props;

  return (
    <>
      <SingleInputAnswerData
        utrv={utrv}
        utr={utr}
        hasPreviousUtrvs={hasPreviousUtrvs}
        predictedAnswer={predictedAnswer}
        isFetching={isFetching}
        targetValue={targetValue}
      />
      <DashboardDivider className='' />
      {hasPreviousUtrvs && SUPPORTED_UTR_TYPES.includes(utr.valueType as UtrValueType) ? (
        <>
          <PreviousAnswers
            utrv={utrv}
            utr={utr}
            previousUtrvs={previousUtrvs}
            predictedAnswer={predictedAnswer}
            isFetching={isFetching}
          />
          <DashboardDivider className='my-3' />
        </>
      ) : null}
    </>
  );
};
