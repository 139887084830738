import IconButton from '@components/button/IconButton';
import {
  Option,
  SelectFactory,
  SelectTypes,
  InputRow,
  TableDraggableColumn,
  TableDraggableRows,
} from '@g17eco/molecules';
import { COLUMNS_MAP, COLUMNS } from './utils';
import { useToggle } from '@hooks/useToggle';
import { Button, Input } from 'reactstrap';
import { Form, OnChangeForm } from './types';
import { AddingColumnsModal } from './AddingColumnsModal';
import classNames from 'classnames';
import { Column, InputColumnRule, isInputColumn } from '@g17eco/types/custom-report';
import { QUESTION } from '@constants/terminology';
import { useState } from 'react';
import { OrderingSetting } from './OrderingSetting';
import { FormatOptionLabelMeta } from 'react-select';

interface Props {
  form: Pick<Form, 'columns' | 'ordering'>;
  onChangeForm: OnChangeForm;
  isEditing: boolean;
}

const inputRuleOptionData: { value: InputColumnRule; label: string; description: string }[] = [
  {
    value: InputColumnRule.Latest,
    label: 'Latest only',
    description: `Export only the latest value for each ${QUESTION.SINGULAR} based on the report filters set.`,
  },
  {
    value: InputColumnRule.All,
    label: 'All submissions',
    description: `Export every submitted value as seen in the ${QUESTION.PLURAL} provenance, based on the report filters set.`,
  },
];

const inputRuleOptions = inputRuleOptionData.map(({ value, label, description }) => ({
  label: (
    <div>
      <div className='strong'>{label}</div>
      <div className='pt-1 pb-2'>{description}</div>
    </div>
  ),
  value,
}));

const formatOptionLabel = (
  option: Option<InputColumnRule> | null,
  meta: FormatOptionLabelMeta<Option<InputColumnRule> | null>
) => {
  if (!option) {
    return <></>;
  }
  const selectedOption = inputRuleOptionData.find((data) => data.value === option.value);
  if (meta.context === 'value' && selectedOption) {
    return <span>{selectedOption.label}</span>;
  }
  return option.label;
};

interface ColumnRuleProps {
  column: Column;
  onChange: (value: string) => void;
}

const ColumnRule = ({ column, onChange }: ColumnRuleProps) => {
  if (!isInputColumn(column)) {
    return null;
  }

  const selectedOption = inputRuleOptions.find((option) => option.value === column.rule) ?? inputRuleOptions[0];

  return (
    <SelectFactory
      selectType={SelectTypes.SingleSelect}
      isSearchable={false}
      options={inputRuleOptions}
      value={selectedOption}
      formatOptionLabel={formatOptionLabel}
      onChange={(op) => (op ? onChange(op.value) : undefined)}
      isMenuPortalTargetBody
    />
  );
};

const draggableColumns: TableDraggableColumn[] = [
  {
    header: 'Heading',
  },
  {
    header: 'Rule',
  },
  {
    header: 'Definition',
  },
  {
    header: '',
  },
];

export const Columns = ({ form, onChangeForm, isEditing }: Props) => {
  const { columns } = form;
  const [isAdding, toggle] = useToggle();
  const [search, setSearch] = useState('');

  const handleRemove = (key: string) => {
    onChangeForm({ columns: columns.filter(({ code }) => code !== key) });
  };

  const handleArrange = (newColumns: string[]) => {
    onChangeForm({ columns: newColumns.map((newColumn) => columns.find(({ code }) => newColumn === code) as Column) });
  };

  const handleChangeRule = ({ code, value }: { code: string; value: string }) => {
    const newColumns = columns.map((column) => {
      if (column.code === code) {
        return {
          ...column,
          rule: value,
        };
      }
      return column;
    });
    onChangeForm({ columns: newColumns });
  };

  const rows: InputRow[] = columns
    .filter((column) => COLUMNS_MAP[column.code].header.toLowerCase().includes(search.trim().toLowerCase()))
    .map((column) => {
      const { code } = column;
      const { header, definition } = COLUMNS_MAP[code];
      return {
        id: code,
        cols: [
          <div key={'header'}>{header}</div>,
          <ColumnRule key={'rule'} column={column} onChange={(value) => handleChangeRule({ code, value })} />,
          <div key={'definition'}>{definition}</div>,
          <div key={'action'} className='text-right'>
            <IconButton
              key={'delete-btn'}
              icon='fal fa-trash text-danger'
              color='link'
              onClick={() => handleRemove(code)}
            />
          </div>,
        ],
      };
    });

  return (
    <>
      {columns.length ? (
        <div className='mb-3 d-flex align-items-center justify-content-between'>
          <Input
            type='text'
            placeholder='Search for a heading...'
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
            className='text-md border-ThemeBorderDefault me-5'
          />
          <OrderingSetting form={form} onChangeForm={onChangeForm} />
        </div>
      ) : null}

      <TableDraggableRows
        columns={draggableColumns}
        data={rows}
        handleUpdate={handleArrange}
        disabled={Boolean(search)}
      />
      {columns.length ? null : (
        <div className={classNames(isEditing ? 'text-ThemeDangerMedium' : 'text-ThemeTextMedium', 'mt-3 text-center')}>
          No headings selected
        </div>
      )}
      <div className='text-center'>
        <Button color='secondary' className='mt-3' onClick={toggle} disabled={columns.length === COLUMNS.length}>
          <i className='fal fa-table-pivot mr-2' /> {columns.length ? 'Manage' : 'Add'} headings
        </Button>
      </div>
      <AddingColumnsModal
        key={`AddingHeadingsModal-${isAdding}`}
        columns={columns}
        open={isAdding}
        toggle={toggle}
        onChangeForm={onChangeForm}
      />
    </>
  );
};
