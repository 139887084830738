import { ColumnDef, Table } from '@g17eco/molecules';
import { Column, ColumnCode } from '@g17eco/types/custom-report';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { COLUMNS_MAP } from './utils';

type PreviewRow = Partial<Record<ColumnCode, string>>;
const previewData: PreviewRow[] = [
  {
    assuranceStatus: '',
    code: '1-1/a',
    note: '',
    mapping: '',
    columnLabel: 'Name of the organization',
    instructions:
      'Reporting in accordance with the GRI Standards enables an organization to provide a comprehensive picture of its most significant impacts on the economy, environment, and people, including impacts on their human rights, and how it manages these impacts. This allows information users to make informed assessments and decisions about the organization’s impacts and its contribution to sustainable development.\nThe organization must comply with all nine requirements in this section to report in accordance with the GRI Standards.\nOverview of in accordance requirements\nRequirement 1: Apply the reporting principles\nRequirement 2: Report the disclosures in GRI 2: General Disclosures 2021\nRequirement 3: Determine material topics\nRequirement 4: Report the disclosures in GRI 3: Material Topics 2021\nRequirement 5: Report disclosures from the GRI Topic Standards for each material topic\nRequirement 6: Provide reasons for omission for disclosures and requirements that the organization cannot comply with\nRequirement 7: Publish a GRI content index\nRequirement 8: Provide a statement of use\nRequirement 9: Notify GRI\nIf the organization does not comply with all nine requirements, it cannot claim that it has prepared the reported information in accordance with the GRI Standards. In such a case, the organization may be able to claim that it has prepared the reported information with reference to the GRI Standards, provided it complies with the requirements specified in ‘Reporting with reference to the GRI Standards’ at the end of this section.',
    valueLabel: 'GRI content index in accordance. ',
    standard: 'GRI 2021',
    numberScale: '',
    provenance: '',
    effectiveDate: 'September 2024',
    status: 'Verified',
    tags: '',
    name: 'Statement of use',
    valueType: 'table',
    unit: '',
    updatedDate: '01/10/2024',
    input: 'Organization name',
  },
  {
    assuranceStatus: '',
    code: '1-1/a',
    note: '',
    mapping: '',
    columnLabel: 'Reporting period start date',
    instructions:
      'Reporting in accordance with the GRI Standards enables an organization to provide a comprehensive picture of its most significant impacts on the economy, environment, and people, including impacts on their human rights, and how it manages these impacts. This allows information users to make informed assessments and decisions about the organization’s impacts and its contribution to sustainable development.\nThe organization must comply with all nine requirements in this section to report in accordance with the GRI Standards.\nOverview of in accordance requirements\nRequirement 1: Apply the reporting principles\nRequirement 2: Report the disclosures in GRI 2: General Disclosures 2021\nRequirement 3: Determine material topics\nRequirement 4: Report the disclosures in GRI 3: Material Topics 2021\nRequirement 5: Report disclosures from the GRI Topic Standards for each material topic\nRequirement 6: Provide reasons for omission for disclosures and requirements that the organization cannot comply with\nRequirement 7: Publish a GRI content index\nRequirement 8: Provide a statement of use\nRequirement 9: Notify GRI\nIf the organization does not comply with all nine requirements, it cannot claim that it has prepared the reported information in accordance with the GRI Standards. In such a case, the organization may be able to claim that it has prepared the reported information with reference to the GRI Standards, provided it complies with the requirements specified in ‘Reporting with reference to the GRI Standards’ at the end of this section.',
    valueLabel: 'GRI content index in accordance. ',
    standard: 'GRI 2021',
    numberScale: '',
    provenance: '',
    effectiveDate: 'September 2024',
    status: 'Verified',
    tags: '',
    name: 'Statement of use',
    valueType: 'table',
    unit: '',
    updatedDate: '01/10/2024',
    input: '2024-01-10',
  },
  {
    assuranceStatus: '',
    code: '1-1/a',
    note: '',
    mapping: '',
    columnLabel: 'Reporting period end date',
    instructions:
      'Reporting in accordance with the GRI Standards enables an organization to provide a comprehensive picture of its most significant impacts on the economy, environment, and people, including impacts on their human rights, and how it manages these impacts. This allows information users to make informed assessments and decisions about the organization’s impacts and its contribution to sustainable development.\nThe organization must comply with all nine requirements in this section to report in accordance with the GRI Standards.\nOverview of in accordance requirements\nRequirement 1: Apply the reporting principles\nRequirement 2: Report the disclosures in GRI 2: General Disclosures 2021\nRequirement 3: Determine material topics\nRequirement 4: Report the disclosures in GRI 3: Material Topics 2021\nRequirement 5: Report disclosures from the GRI Topic Standards for each material topic\nRequirement 6: Provide reasons for omission for disclosures and requirements that the organization cannot comply with\nRequirement 7: Publish a GRI content index\nRequirement 8: Provide a statement of use\nRequirement 9: Notify GRI\nIf the organization does not comply with all nine requirements, it cannot claim that it has prepared the reported information in accordance with the GRI Standards. In such a case, the organization may be able to claim that it has prepared the reported information with reference to the GRI Standards, provided it complies with the requirements specified in ‘Reporting with reference to the GRI Standards’ at the end of this section.',
    valueLabel: 'GRI content index in accordance. ',
    standard: 'GRI 2021',
    numberScale: '',
    provenance: '',
    effectiveDate: 'September 2024',
    status: 'Verified',
    tags: '',
    name: 'Statement of use',
    valueType: 'table',
    unit: '',
    updatedDate: '01/10/2024',
    input: '2025-01-10',
  },
  {
    assuranceStatus: '',
    code: '1-1/a',
    note: '',
    mapping: '',
    columnLabel:
      'The organization has reported in accordance with the GRI Standards for the reporting period mentioned above',
    instructions:
      'Reporting in accordance with the GRI Standards enables an organization to provide a comprehensive picture of its most significant impacts on the economy, environment, and people, including impacts on their human rights, and how it manages these impacts. This allows information users to make informed assessments and decisions about the organization’s impacts and its contribution to sustainable development.\nThe organization must comply with all nine requirements in this section to report in accordance with the GRI Standards.\nOverview of in accordance requirements\nRequirement 1: Apply the reporting principles\nRequirement 2: Report the disclosures in GRI 2: General Disclosures 2021\nRequirement 3: Determine material topics\nRequirement 4: Report the disclosures in GRI 3: Material Topics 2021\nRequirement 5: Report disclosures from the GRI Topic Standards for each material topic\nRequirement 6: Provide reasons for omission for disclosures and requirements that the organization cannot comply with\nRequirement 7: Publish a GRI content index\nRequirement 8: Provide a statement of use\nRequirement 9: Notify GRI\nIf the organization does not comply with all nine requirements, it cannot claim that it has prepared the reported information in accordance with the GRI Standards. In such a case, the organization may be able to claim that it has prepared the reported information with reference to the GRI Standards, provided it complies with the requirements specified in ‘Reporting with reference to the GRI Standards’ at the end of this section.',
    valueLabel: 'GRI content index in accordance. ',
    standard: 'GRI 2021',
    numberScale: '',
    provenance: '',
    effectiveDate: 'September 2024',
    status: 'Verified',
    tags: '',
    name: 'Statement of use',
    valueType: 'table',
    unit: '',
    updatedDate: '01/10/2024',
    input: 'Yes',
  },
];

interface Props {
  open: boolean;
  toggle: () => void;
  columns: Column[];
}

export const PreviewModal = ({ open, toggle, columns }: Props) => {
  const tableColumns: ColumnDef<PreviewRow>[] = columns.map((col) => ({
    header: COLUMNS_MAP[col.code].header,
    cell: ({ row }) => row.original[col.code],
    meta: {
      cellProps: {
        className: 'text-truncate',
        style: { maxWidth: 150 },
      },
    },
  }));
  return (
    <Modal isOpen={open} toggle={toggle} backdrop='static' keyboard>
      <ModalHeader toggle={toggle}>Custom report preview</ModalHeader>
      <ModalBody>
        <Table columns={tableColumns} data={previewData} className='overflow-scroll' />
      </ModalBody>
      <ModalFooter>
        <Button color='link-secondary' onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
