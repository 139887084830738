import {
  useCreateTemplateDashboardMutation,
  useGetInsightDashboardsByInitiativeQuery,
  useGetInsightDashboardTemplatesQuery,
} from '@api/insight-dashboards';
import { ROUTES } from '@constants/routes';
import { DashboardTemplateType } from '@g17eco/types/insight-custom-dashboard';
import { useAppSelector } from '@reducers/index';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { InsightPage } from '@routes/summary/insights/utils/constants';
import { getValidPage, getInsightPageOptions, isInsightLayoutPage } from '@routes/summary/insights/utils/helpers';
import { getAddDashboardOption, InsightDashboardOption, TOOLTIP_MESSAGE } from '@routes/summary/insights/utils/sidebar';
import { generateUrl } from '@routes/util';
import { canManageCurrentLevel } from '@selectors/user';
import { FeaturePermissions } from '@services/permissions/FeaturePermissions';
import { useHistory } from 'react-router-dom';

interface Props {
  initiativeId: string | undefined;
  dashboardId?: string;
  summaryPage?: InsightPage;
  surveyId?: string;
  handleAddNew: () => void;
}

export const useCTCustomDashboards = ({
  initiativeId,
  dashboardId = '',
  summaryPage: summaryPageParam = InsightPage.Overview,
  surveyId = 'current',
  handleAddNew = () => {},
}: Props) => {
  const history = useHistory();
  const canAccessCustomDashboards = useAppSelector(FeaturePermissions.canAccessCustomDashboards);
  const canManage = useAppSelector(canManageCurrentLevel);

  const { data: dashboards, isFetching: isFetchingDashboards } = useGetInsightDashboardsByInitiativeQuery(
    initiativeId ?? skipToken
  );

  const insightPageOptions: InsightDashboardOption[] = getInsightPageOptions();

  const dashboardOptions: InsightDashboardOption[] = (dashboards || []).map((dashboard) => ({
    value: dashboard._id,
    label: dashboard.title,
    isCustom: true,
    disabled: !canAccessCustomDashboards,
    tooltip: !canAccessCustomDashboards ? TOOLTIP_MESSAGE.NOT_AVAILABLE_PLAN : undefined,
    isSharedByParent: initiativeId !== dashboard.initiativeId,
  }));

  const { data: templates } = useGetInsightDashboardTemplatesQuery(initiativeId ?? skipToken);
  const [createTemplateDashboard, { isLoading: isCreatingTemplateDashboard }] = useCreateTemplateDashboardMutation();

  const addDashboardOption = getAddDashboardOption({
    canAccessCustomDashboards,
    templates,
  });

  const options = [...insightPageOptions, ...dashboardOptions, ...(canManage ? [addDashboardOption] : [])];

  const summaryPage = isInsightLayoutPage(summaryPageParam) ? summaryPageParam : undefined;
  const currentPage = dashboardId || getValidPage(summaryPage);

  const handleNavigateCustom = (dashboardId: string) => {
    history.push(generateUrl(ROUTES.CUSTOM_DASHBOARD, { initiativeId, surveyId, dashboardId }));
  };

  const handleNavigate = (page: string) => {
    const url = generateUrl(ROUTES.SUMMARY, {
      initiativeId,
      surveyId,
      summaryPage: page !== InsightPage.Overview ? page : undefined,
    });
    history.push(url);
  };

  const handleClickOption = async (item: InsightDashboardOption) => {
    if (item.disabled) {
      return;
    }

    if (item.value === addDashboardOption.value) {
      return handleAddNew();
    }


    if (initiativeId && Object.values(DashboardTemplateType).includes(item.value as DashboardTemplateType)) {
      const dashboard = await createTemplateDashboard({
        initiativeId,
        templateType: item.value as DashboardTemplateType,
      }).unwrap();
      return handleNavigateCustom(dashboard._id);
    }

    return item.isCustom ? handleNavigateCustom(item.value) : handleNavigate(item.value);
  };

  return {
    currentPage,
    options,
    isFetchingDashboards: isFetchingDashboards || isCreatingTemplateDashboard,
    handleNavigateCustom,
    handleNavigate,
    handleClickOption,
  };
};
