
import React from 'react';
import { genericInputGroup, genericInputGroupNumberScale } from '../InputGroup';
import { ColumnInputProps } from './InputInterface';
import { getUnitsForType, UnitTypes } from '../../../../../utils/units';
import { InputGroupText } from 'reactstrap';

export function TableInputGroupAppend(props: ColumnInputProps) {

  const {
    updateColumn,
    column: { code, unitType, unit, numberScale },
    inputColumn,
    isDisabled,
    suffix,
    isInvalid,
  } = props;
  const options = getUnitsForType(unitType);
  if (unitType === UnitTypes.currency) {
    return genericInputGroupNumberScale({
      isDisabled,
      isInvalid,
      numberScale: inputColumn.numberScale ?? numberScale,
      options,
      handleNumberScaleChange: (numberScale: string) => updateColumn({ code, numberScale }),
    });
  }

  return (
    <>
      {genericInputGroupNumberScale({
        isDisabled,
        isInvalid,
        numberScale: inputColumn.numberScale ?? numberScale,
        options: getUnitsForType(UnitTypes.numberScale),
        handleNumberScaleChange: (numberScale: string) => updateColumn({ code, numberScale }),
      })}
      {genericInputGroup({
        options,
        isDisabled,
        isInvalid,
        unit: inputColumn.unit ?? unit, // dropdown value
        suffix,
        value: inputColumn.value, // should use singular/plurar text
        handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) =>
          updateColumn({ code, unit: e.target.value }),
      })}
    </>
  );
}

export const InputTableGroupPrePend = (props: ColumnInputProps) => {

  const { column: { unitType, unit }, isInvalid } = props;

  // Table question use default survey configuration unitConfig.currency value
  const currentUnit = props.unitConfig?.currency || unit;
  const prefixString = unitType === UnitTypes.currency ? currentUnit : '';
  if (!prefixString) {
    return null;
  }

  return (
    <InputGroupText className={`${isInvalid ? 'styled-readonly-invalid' : 'styled-readonly'} px-4`}>
      {prefixString}
    </InputGroupText>
  );
}
