import { UniversalTrackerPlain, UtrValueType } from '@g17eco/types/universalTracker';
import { SUPPORTED_UTR_TYPES } from './constants';
import { TableDataInfo } from '@components/survey/question/questionInterfaces';
import { MultiRowTableContext } from '@g17eco/types/ai';
import { AnswerProps } from './types';
import { isMultiRowTableType } from '@utils/universalTracker';

enum ValueEvaluation {
  Neutral = 'neutral',
  Good = 'good',
  Bad = 'bad',
}

export const getValueEvaluation = ({
  value,
  targetValue,
  targetDirection,
}: {
  value: number;
  targetValue: number;
  targetDirection: string;
}) => {
  if (value === targetValue) {
    return ValueEvaluation.Neutral;
  }

  const targetDirectionFactor = targetDirection === 'decrease' ? -1 : 1;
  const valueFactor = value < targetValue ? -1 : 1;

  return targetDirectionFactor * valueFactor > 0 ? ValueEvaluation.Good : ValueEvaluation.Bad;
};

export const badgeColorMap = {
  [ValueEvaluation.Neutral]: 'secondary',
  [ValueEvaluation.Good]: 'success',
  [ValueEvaluation.Bad]: 'warning',
};

export const getChangePercentage = ({ answer, targetValue }: { answer: number; targetValue: number }) => {
  return Number(((Math.abs(answer - targetValue) / targetValue) * 100).toFixed(2));
};

export const getNumberAnswer = ({
  inputAnswer,
  predictedAnswer,
}: {
  inputAnswer: number | undefined;
  predictedAnswer: string | number;
}) => {
  const hasAnswered = inputAnswer !== undefined;
  const answer = hasAnswered ? inputAnswer : Number(predictedAnswer);

  return { hasAnswered, answer };
};

export const isSupportedPrediction = (utr: Pick<UniversalTrackerPlain, 'valueType' | 'valueValidation'>) => {
  return SUPPORTED_UTR_TYPES.includes(utr.valueType as UtrValueType);
};

export const getAnswerTooltip = (hasPreviousUtrvs: boolean) =>
  hasPreviousUtrvs
    ? 'Estimate based on data analysis from previous years and identifying trends.'
    : 'Estimate based on analysing data from similarly sized companies in comparable sectors. Accuracy improves if previous years data is available.';

export const getAdditionalContext = ({ utr, table }: Pick<AnswerProps, 'utr' | 'table'>) => {
  switch (utr.valueType) {
    case UtrValueType.Table: {
      if (isMultiRowTableType(utr)) {
        const { rows, editRowId } = table;
        const columns = utr.valueValidation?.table?.columns ?? [];

        return {
          additionalContext: {
            inputData: columns.map((col) => {
              const data = rows[editRowId]?.data?.find((item) => item.code === col.code);
              return {
                code: col.code,
                value: Array.isArray(data?.value) ? data.value.join(', ') : data?.value,
              };
            }),
          },
        };
      }
      return {};
    }
    default:
      return {};
  }
};
