import { InputOverrideDataType, InputOverrideFormType } from '@features/question-configuration/utils';
import UniversalTracker, { UniversalTrackerBase } from '../../model/UniversalTracker';
import { InitiativeUniversalTracker } from '../../types/initiativeUniversalTracker';
import { UtrValueType, ValueValidation } from '../../types/universalTracker';
import { isDefined } from '../../utils';
import { hasNumericColumnType, isNumericTableColumnType, isNumericValueType } from '../../utils/universalTracker';
import { BulkActionUtr } from '../survey-question-list/partials/BulkActionToolbar';
import { TableColumn } from '../survey/form/input/table/InputInterface';

export const DECIMAL_MIN = 0;
export const DECIMAL_MAX = 5;

export const invalidInputMessage = `Enter a value between ${DECIMAL_MIN}-${DECIMAL_MAX}`;

export type DecimalType = Record<string, InputOverrideDataType<number>>;

export interface ErrorDecimalType {
  [key: string]: string | undefined;
}

const COLUMN_CODES = [
  'standard',
  'code',
  'mapping',
  'valueType',
  'name',
  'valueLabel',
  'columnLabel',
  'unit',
  'numberScale',
  'combinedStatus',
  'input',
  'note',
  'tags',
  'assurance',
  'provenance',
  'instructions',
  'status',
  'assuranceStatus',
  'updatedDate',
  'effectiveDate',
] as const;

export type ColumnCode = (typeof COLUMN_CODES)[number];
export const isSingleTableQuestion = (questions: BulkActionUtr[]) => {
  return questions.length === 1 && questions[0].valueType === UtrValueType.Table;
};

export const getInputType = (numericQuestions: BulkActionUtr[]) => {
  if (!numericQuestions.length) {
    return InputOverrideFormType.SingleInput;
  }
  return isSingleTableQuestion(numericQuestions) ? InputOverrideFormType.Table : InputOverrideFormType.SingleInput;
};

export const getInitialState = ({
  inputType,
  tableColumns,
  valueValidation,
}: {
  inputType: InputOverrideFormType;
  tableColumns: TableColumn[];
  valueValidation: ValueValidation | undefined;
}): DecimalType => {
  switch (inputType) {
    case InputOverrideFormType.Table:
      return Object.fromEntries(
        tableColumns.map((k) => {
          const value = valueValidation?.table?.columns?.find((c) => c.code === k.code)?.validation?.decimal;
          return [[k.code], { value, isEnforced: isDefined(value) }];
        })
      );
    case InputOverrideFormType.SingleInput:
    default: {
      const value =
        valueValidation?.decimal ??
        valueValidation?.table?.columns?.find(
          (c) => isNumericTableColumnType(c.type) && isDefined(c.validation?.decimal)
        )?.validation?.decimal;

      return { __DATA__: { value, isEnforced: isDefined(value) } };
    }
  }
};

export const isMultipleUtrUpdate = ({
  overriddenQuestions,
  selectedQuestions,
}: {
  overriddenQuestions: InitiativeUniversalTracker[];
  selectedQuestions: BulkActionUtr[];
}) => {
  const hasMultipleInitiativeUtrs = overriddenQuestions.length > 1;
  const hasAtLeastOneInitiativeUtr = selectedQuestions.length > 1 && overriddenQuestions.length === 1;

  return hasMultipleInitiativeUtrs || hasAtLeastOneInitiativeUtr;
};

export const convertValueByDecimal = ({
  value,
  decimal,
}: {
  decimal: number | undefined;
  value: string | number | undefined;
}) => {
  if (!value || !decimal) {
    return value;
  }

  return Number(value).toFixed(decimal);
};

export const getUtrDecimal = (
  utr: Pick<UniversalTracker, 'getValueType' | 'getValueValidation'> | UniversalTrackerBase | undefined,
  inputName?: string
) => {
  if (!utr) {
    return;
  }
  const valueType: UtrValueType = 'getValueType' in utr ? utr.getValueType() : (utr.valueType as UtrValueType);

  const valueValidation: ValueValidation | undefined =
    'getValueValidation' in utr ? utr.getValueValidation() : utr.valueValidation;

  if (!valueValidation) {
    return;
  }

  if (isNumericValueType(valueType)) {
    return valueValidation?.decimal;
  }

  if (hasNumericColumnType({ valueValidation })) {
    return getTableColumnDecimal(valueValidation, inputName);
  }

  return;
};

const getTableColumnDecimal = (valueValidation: ValueValidation, inputName?: string) => {
  const tableColumn = valueValidation.table?.columns.find((col: TableColumn) => col.code === inputName);

  if (!tableColumn || !isNumericTableColumnType(tableColumn.type) || tableColumn.calculation) {
    return;
  }

  return tableColumn?.validation?.decimal;
};
