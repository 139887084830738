import React, { ReactNode } from 'react';
import { HistoricalUtrs, HistoricalUtrsQueryByCodeParams } from '@api/insights';
import { InsightDashboard, InsightDashboardSettingKeys } from '@g17eco/types/insight-custom-dashboard';
import { useAppSelector } from '../../../reducers';
import { isStaff } from '../../../selectors/user';
import { IntegrationSource } from '@g17eco/types/integration';


interface CustomDashboardProps {
  children: ReactNode;
  getHistoricalUtrsByCodes: (params: HistoricalUtrsQueryByCodeParams) => Promise<HistoricalUtrs[]>;
  duplicateDashboard: () => Promise<InsightDashboard>;
  integrationProviders?: IntegrationSource[] ;
  hideOptions?: InsightDashboardSettingKeys[];
  hideShareButton?: boolean;
  hideQuestionReference?: boolean;
  readOnly?: boolean;
}

export interface InsightDashboardContextProps extends Omit<CustomDashboardProps, 'children'> {
  userIsStaff: boolean;
}

const initialState: InsightDashboardContextProps = {
  getHistoricalUtrsByCodes: () => Promise.resolve([]),
  duplicateDashboard: () => Promise.resolve({} as InsightDashboard),
  hideOptions: [],
  hideShareButton: false,
  hideQuestionReference: false,
  readOnly: false,
  userIsStaff: false,
};

export const CustomDashboardContext = React.createContext<InsightDashboardContextProps>(initialState);

export const CustomDashboardWrapper = ({
  getHistoricalUtrsByCodes,
  duplicateDashboard,
  hideOptions,
  hideQuestionReference,
  hideShareButton,
  readOnly,
  integrationProviders,
  children,
}: CustomDashboardProps) => {
  // used to put the multiple utrs under staff flag for now
  const userIsStaff = useAppSelector(isStaff);
  return (
    <CustomDashboardContext.Provider
      value={{
        getHistoricalUtrsByCodes,
        duplicateDashboard,
        integrationProviders,
        hideOptions,
        hideQuestionReference,
        hideShareButton,
        readOnly,
        userIsStaff,
      }}
    >
      {children}
    </CustomDashboardContext.Provider>
  );
};
