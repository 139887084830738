import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import '../styles.scss';
import { ValidationError } from '../../../types/background-jobs';

interface ImportIssuesModalProps {
  toggle: () => void;
  validationErrors: ValidationError[];
}

export const ImportIssuesModal = ({ validationErrors, toggle }: ImportIssuesModalProps) => {
  return (
    <Modal isOpen={true} toggle={toggle} backdrop='static' size={'lg'} className='confirm-modal'>
      <ModalHeader toggle={toggle}>Import issues</ModalHeader>
      <ModalBody>
        <div className='mb-2'>
          <h5 className='mt-0'>Issues :</h5>
          <div className='mt-2'>
            {validationErrors.map((error) => (
              <div key={error.message} className='mb-1'>{error.message}</div>
            ))}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
