import { CustomMetric } from '@api/custom-metrics';
import { DeleteModal } from '@g17eco/molecules';
import { QUESTION } from '@constants/terminology';
import { SurveyById, useGetSurveysByUtrIdQuery } from '@api/surveys';
import { getSurveyName } from '@utils/surveyData';

const getContent = (surveys: SurveyById[] = []) => {
  return (
    <>
      <p>
        You have chosen to <span className='text-ThemeDangerDark'>delete</span> the custom metric. It will be deleted
        entirely, including from any metric groups it is in, and cannot be recovered.
      </p>
      {surveys.length ? (
        <>
          Please note this metric is currently in the following surveys:
          <ul>
            {surveys.map((survey) => (
              <li key={survey._id}>
                {getSurveyName({
                  ...survey,
                  initiatives: [survey.initiative],
                })}
              </li>
            ))}
          </ul>
        </>
      ) : null}
    </>
  );
};

interface Props {
  utr: CustomMetric;
  handleDelete: () => void;
  handleCancel: () => void;
}

export const DeleteMetricModal = ({ utr, handleDelete, handleCancel }: Props) => {
  const { data: surveys } = useGetSurveysByUtrIdQuery(utr._id, { skip: !utr._id });

  const content = getContent(surveys);

  return (
    <DeleteModal
      title={`custom ${QUESTION.SINGULAR}`}
      content={content}
      isOpen
      handleDelete={handleDelete}
      handleCancel={handleCancel}
    />
  );
};
