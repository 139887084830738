export enum JobType {
  Export = 'export',
  BulkSurveyCreate = 'bulk_survey_create',
  BulkSurveyImport = 'bulk_survey_import',
}

export enum JobStatus {
  Pending = 'pending',
  Processing = 'processing',
  WaitForRun = 'waitForRun',
  Error = 'error',
  Completed = 'completed',
  Deleted = 'deleted'
}

export const JobNotFoundStatus = 'notFound';

/**
 * Identical for now with JobStatus but could be expanded later
 * @link JobStatus
 **/
export enum TaskStatus {
  Pending = 'pending',
  Processing = 'processing',
  Error = 'error',
  Completed = 'completed',
}

/**
 * We probably want to get rid of this as JobType should control what is allowed?
 **/
export enum TaskType {
  SgxExport = 'sgx_export',
  SurveysBulkCreate = 'surveys_bulk_create',
  AggregatedSurveysBulkCreate = 'aggregated_surveys_bulk_create',

  // Bulk Data import tasks
  UploadFile = 'upload_file',
  /** Process uploaded file **/
  ProcessFile = 'process_file',

  ImportData = 'import_data',
}

enum ValidationErrorType {
  MissingInitiative = 'missing_initiative',
  MissingSurvey = 'missing_survey',
  Generic = 'generic',
}

export type ValidationError =
  | {
      initiativeCode: string;
      type: ValidationErrorType.MissingInitiative;
      message: string;
    }
  | {
      initiativeCode: string;
      effectiveDate: string;
      type: ValidationErrorType.MissingSurvey;
      message: string;
    }
  | {
      type: ValidationErrorType.Generic;
      message: string;
    };

type DefaultTaskData = Record<string, unknown>;

export interface Task<TaskData extends DefaultTaskData = DefaultTaskData, T extends TaskType = TaskType> {
  /** Represent UUID4 **/
  id: string;
  /**
   *  Specifies the particular task implementation that Background Task performs.
   */
  type: T;
  name: string;
  status: TaskStatus;
  data: TaskData;
  completedDate?: Date;
}

export type UploadFileTask = Task<
  {
    /** Upload path relative to the currently selected bucket **/
    uploadPath: string;

    /** Signed Url expiration date, after this signedUploadUrl is no longer usable **/
    expirationDate: Date;

    /** Uploaded file name and size **/
    file: {
      name: string;
      size: number;
    };
  },
  TaskType.UploadFile
>;

// Process the files from uploadUrl and generate task to import
export type ProcessFileTask = Task<
  {
    uploadedFile: string;
    validRowCount: number;
    /**
     * Non-critical errors for user to review like missing initiatives or survey dates
     */
    errors?: ValidationError[];
  },
  TaskType.ProcessFile
>;

interface ImportOutcome {
  message: string;
  date: string;
  type: 'success' | 'error';
  context?: Record<string, string | number | string[]>;
}

interface BaseImportResult {
  /**
   * Upload file path relative to the storage bucket
   * Expected to be like /jobs/:jobId/tasks/:taskId/:filePath
   **/
  filePath: string;
  initiativeId: string;
  initiativeCode: string;
  surveyId: string;
  effectiveDate: string;
  outcome?: ImportOutcome;
}

interface FinalImportResult extends BaseImportResult {
  outcome: ImportOutcome;
}

// Not yet processed or one of the final version
type ImportResult = BaseImportResult | FinalImportResult;

export type ImportDataTask = Task<
  {
    /**
     * JSON files generated in previous step to process.
     * These should be ready to be consumed by the final Utrv data importer
     * and generate results for each.
     */
    results: ImportResult[];
    /**
     * Non-critical errors for user to review like missing initiatives or survey dates
     */
    errors: ValidationError[];
  },
  TaskType.ImportData
>;

export type SupportedTask = UploadFileTask | ProcessFileTask | ImportDataTask;

export interface LogMessage {
  severity: number;
  message: string;
  created: Date;
  metadata?: unknown;
}

export interface Attribute {
  name: string;
  value: string;
}

export interface BackgroundJob<Tasks extends Task[] = Task[]> {
  _id: string;
  name: string;
  type: JobType;
  status: JobStatus;
  tasks: Tasks;
  attributes: Attribute[];
  priority: number;
  retryCount: number;
  logs: LogMessage[];
  idempotencyKey?: string;
  /** User who created the job, otherwise assume this is system created job */
  userId?: string;
  initiativeId?: string;
  created: Date;
  updated: Date;
  completedDate?: Date;
  deletedDate?: Date;
}

export type ImportJobPlain = BackgroundJob<SupportedTask[]> & { type: JobType.BulkSurveyImport };

export type CreateJobResult = {
  jobId: string;
  taskId: string;
};
