import { ColumnDef, Table } from '@g17eco/molecules';
import { ORDERED_COLUMN_CODES, ColumnData, COLUMNS } from './utils';
import { Checkbox, CheckboxState } from '@g17eco/atoms';
import { useState } from 'react';
import { Form, OnChangeForm } from './types';
import { compareStringArrays } from '@utils/string';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { isArraysEqual } from '@utils/array';
import { ColumnCode } from '@g17eco/types/custom-report';

type Props = Pick<Form, 'columns'> & {
  open: boolean;
  toggle: () => void;
  onChangeForm: OnChangeForm;
};

export const AddingColumnsModal = ({ columns, open, toggle, onChangeForm }: Props) => {
  const columnCodes = columns.map(({ code }) => code);
  const [selectedColumnCodes, setSelectedColumnCodes] = useState<ColumnCode[]>(columnCodes);
  const [search, setSearch] = useState('');

  const toggleAll = () =>
    setSelectedColumnCodes((selectedColumnCodes) =>
      selectedColumnCodes.length === ORDERED_COLUMN_CODES.length ? [] : ORDERED_COLUMN_CODES
    );
  const toggleSelect = (newKey: ColumnCode) =>
    setSelectedColumnCodes((selectedColumnCodes) =>
      selectedColumnCodes.includes(newKey)
        ? selectedColumnCodes.filter((key) => key !== newKey)
        : [...selectedColumnCodes, newKey]
    );

  const handleSubmit = () => {
    // Have to keep the older of current headings.
    const { added, removed } = compareStringArrays({
      oldArray: columnCodes,
      newArray: selectedColumnCodes,
    });

    const newColumnCodes = [...columnCodes.filter((key) => !removed.includes(key)), ...added];

    onChangeForm({
      columns: newColumnCodes.map((code) => {
        // Have to keep other settings of column.
        const column = columns.find((column) => column.code === code);
        return column ? column : { code };
      }),
    });

    toggle();
  };

  const searchText = search.trim().toLowerCase();
  const data = COLUMNS.reduce((data, { code, header, definition }) => {
    if (header.toLowerCase().includes(searchText)) {
      data.push({ code, header, definition });
    }

    return data;
  }, [] as ColumnData[]);

  const isSelectedBefore = (code: ColumnCode) => selectedColumnCodes.includes(code) && columnCodes.includes(code);

  const tableColumns: ColumnDef<ColumnData>[] = [
    {
      header: 'Heading',
      cell: (c) =>
        isSelectedBefore(c.row.original.code) ? (
          <span className='text-ThemeTextLight'>{c.row.original.header}</span>
        ) : (
          c.row.original.header
        ),
    },
    {
      header: 'Definition',
      cell: (c) =>
        isSelectedBefore(c.row.original.code) ? (
          <span className='text-ThemeTextLight'>{c.row.original.definition}</span>
        ) : (
          c.row.original.definition
        ),
    },
    {
      id: 'selectBtn',
      header: () => (
        <span onClick={toggleAll} className='cursor-pointer'>
          Select all
        </span>
      ),
      cell: (c) => (
        <Checkbox
          status={selectedColumnCodes.includes(c.row.original.code) ? CheckboxState.Checked : CheckboxState.Unchecked}
          onChange={() => toggleSelect(c.row.original.code)}
        />
      ),
    },
  ];

  const disabled = !selectedColumnCodes.length || isArraysEqual(selectedColumnCodes, columnCodes);

  return (
    <Modal isOpen={open} toggle={toggle} backdrop='static'>
      <ModalHeader toggle={toggle}>Adding headings</ModalHeader>
      <ModalBody>
        <Input
          type='text'
          placeholder='Search for a heading'
          value={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
          className='text-md border-ThemeBorderDefault'
        />
        <Table
          columns={tableColumns}
          responsive
          data={data}
          noData='No available headings'
          className='overflow-scroll mt-3'
          style={{ maxHeight: '60vh' }}
        />
      </ModalBody>
      <ModalFooter>
        <Button color='link-secondary' onClick={toggle}>
          Cancel
        </Button>
        <Button color='primary' onClick={handleSubmit} disabled={disabled}>
          Update columns
        </Button>
      </ModalFooter>
    </Modal>
  );
};
