import React, { useContext } from 'react';
import { Button, Input } from 'reactstrap';
import { isValidEmail } from '@utils/index';
import { ACTION, UserInvitationFormContext } from '../UserInvitationFormContainer';

interface EmailChipsInputProps {
  emailInput: string,
  emails: string[],
  placeholder: string,
  onChangeEmailInput: (e: React.ChangeEvent<any>) => void,
  handleDelete: (item: string) => void,
}

export const EmailChipsInput = ({
  emailInput,
  emails,
  placeholder,
  onChangeEmailInput,
  handleDelete,
}: EmailChipsInputProps) => {
  const { dispatch } = useContext(UserInvitationFormContext);
  const validEmail = isValidEmail(emailInput);

  const onKeyDown = (e: React.KeyboardEvent<any>) => {
    if (['Enter', 'Tab', ','].includes(e.key)) {
      e.preventDefault();
      handleAdd();
    }
  };

  const handleAdd = () => {
    dispatch({
      type: ACTION.ADD_EMAIL,
    });
    dispatch({
      type: ACTION.SET_EMAIL_INPUT,
      payload: '',
    });
  };

  return (
    <>
      {emails.map((email) => (
        <div className='email-chip' key={email}>
          {email}
          <button type='button' className='chip-delete-button' onClick={() => handleDelete(email)}>
            &times;
          </button>
        </div>
      ))}
     <div className='d-block d-md-flex'>
      <Input
        valid={validEmail}
        invalid={!!emailInput && !validEmail}
        type='email'
        value={emailInput}
        placeholder={placeholder}
        onChange={onChangeEmailInput}
        onKeyDown={onKeyDown}
        className='mr-2 mb-2'
        data-testid='add-user-email-input'
      ></Input>
      <Button className='mb-2 col-md-3' outline={true} disabled={!validEmail} onClick={handleAdd} data-testid='invite-additional-user-btn'>
        Invite additional user
      </Button>
     </div>
    </>
  );
};
