import { getUnitByTypeOptions, getUnitLabel, UnitTypeMapValue, UnitConfigType } from '@features/question-configuration';
import { SelectFactory, SelectTypes } from '@g17eco/molecules';
import { FormGroup, Input, Label } from 'reactstrap';
import { GLOBAL_UPDATE_CODE, InputOverrideType } from '../utils';
import { UnitTypes } from '@utils/units';

export interface UnitOverrideProps {
  unitTypeMap: Map<string, UnitTypeMapValue>;
  unitConfig: UnitConfigType;
  columnCode?: string;
  type: InputOverrideType;
  onSelectUnit: (props: { code: string; unitType: string; value: string | undefined }) => void;
  onToggleSwitch: (props: { code: string; type: InputOverrideType }) => void;
}

export const getInputLabel = (type: InputOverrideType) => {
  switch (type) {
    case InputOverrideType.NumberScale:
      return 'Enforce number scale';
    case InputOverrideType.Unit:
      return 'Set unit override';
    default:
      return '';
  }
};

const OverrideUnitSelect = (props: UnitOverrideProps & { isDisabled: boolean }) => {
  const { unitTypeMap, unitConfig, columnCode, onSelectUnit, isDisabled } = props;
  const code = columnCode ?? GLOBAL_UPDATE_CODE;
  const selectedUnitTypes = [...unitTypeMap.keys()];

  if (!selectedUnitTypes.length || !unitConfig) {
    return null;
  }

  if (selectedUnitTypes.length === 1) {
    const [unitType] = selectedUnitTypes;
    const options = getUnitByTypeOptions(unitType);
    const { defaultValue } = unitTypeMap.get(unitType) ?? {};

    return (
      <div className='col-6'>
        <SelectFactory
          key={unitType}
          placeholder={defaultValue ?? 'Not set'}
          selectType={SelectTypes.SingleSelect}
          options={options}
          value={options.find((op) => op.value === unitConfig[code][unitType].value) ?? null}
          onChange={(op) => onSelectUnit({ code, unitType, value: op?.value })}
          isMenuPortalTargetBody
          menuPlacement='top'
          isDisabled={isDisabled}
        />
      </div>
    );
  }

  return (
    <div className='col-12'>
      {selectedUnitTypes.map((unitType) => {
        const options = getUnitByTypeOptions(unitType);
        const { defaultValue } = unitTypeMap.get(unitType) ?? {};

        return (
          <div key={unitType} className='row g-0 mt-2'>
            <Label for={unitType} className='col-4 mt-2'>
              {getUnitLabel(unitType)}
            </Label>
            <SelectFactory
              key={unitType}
              placeholder={defaultValue ?? 'Not set'}
              selectType={SelectTypes.SingleSelect}
              options={options}
              value={options.find((op) => op.value === unitConfig[code][unitType].value) ?? null}
              onChange={(op) => onSelectUnit({ code, unitType, value: op?.value })}
              className='col-8'
              menuPlacement='top'
              isMenuPortalTargetBody
              isDisabled={isDisabled}
            />
          </div>
        );
      })}
    </div>
  );
};

export const OverrideUnitForm = (props: UnitOverrideProps) => {
  const { type, columnCode, onToggleSwitch, unitConfig } = props;
  const code = columnCode ?? GLOBAL_UPDATE_CODE;
  const isEnforced = (() => {
    // get number scale enforce
    if (type === InputOverrideType.NumberScale) {
      return unitConfig[code][UnitTypes.numberScale].isEnforced;
    }
    // get unit enforce, only 1 toggle for all unit, therefore, just need to get the first one value
    const firstUnitType = Object.keys(unitConfig[code]).find((key) => key !== UnitTypes.numberScale);
    return firstUnitType ? unitConfig[code][firstUnitType].isEnforced : false;
  })();

  return (
    <div className='row gx-0 mb-2'>
      <FormGroup switch className='col-6 d-flex align-items-center' id={`${type}-${code}`}>
        <Input type='switch' className='mr-2' onChange={() => onToggleSwitch({ type, code })} checked={isEnforced} />
        <label htmlFor={`${type}-${code}`}> {getInputLabel(type)}</label>
      </FormGroup>
      <OverrideUnitSelect {...props} isDisabled={!isEnforced} />
    </div>
  );
};
