import { GridDashboardTextItem, InsightDashboardItemType } from '@g17eco/types/insight-custom-dashboard';

const commonClassnames = 'w-100 h-100 d-flex align-items-start text-break overflow-auto';

type Props = Pick<GridDashboardTextItem, 'type'> & {
  text: string;
  isEditing?: boolean;
};

export const TextWidget = ({ type, text, isEditing }: Props) => {
  switch (type) {
    case InsightDashboardItemType.Headline:
      return <Headline text={text} isEditing={isEditing} />;
    case InsightDashboardItemType.Text:
      return <TextArea text={text} isEditing={isEditing} />;
    default:
      return null;
  }
};

const TextArea = ({ text, isEditing }: Pick<Props, 'text' | 'isEditing'>) => {
  const className = isEditing ? 'background-ThemeBgExtralight' : 'border border-ThemeBorderDefault border-1 rounded-1';

  return (
    <div
      className={`${className} ${commonClassnames} text-ThemeTextMedium p-3`}
    >
      {text}
    </div>
  );
};

const Headline = ({ text, isEditing }: Pick<Props, 'text' | 'isEditing'>) => {
  return (
    <h2
      className={`px-3 ${commonClassnames} text-ThemeHeadingLight ${
        isEditing ? 'background-ThemeBgExtralight' : ''
      } m-0`}
    >
      {text}
    </h2>
  );
};
