import { CommonData } from '@g17eco/types/common';
import { g17ecoApi, transformResponse } from './g17ecoApi';

const TAGS = ['common-data'];

export const commonApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: TAGS,
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      loadCommonData: builder.query<CommonData, void | undefined>({
        transformResponse,
        query: () => ({
          url: 'o/common/all',
          method: 'get',
        }),
        providesTags: TAGS,
      }),
    }),
  });

export const { useLoadCommonDataQuery } = commonApi;
