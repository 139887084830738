import confetti from 'canvas-confetti';

export const runConfetti = () => {
  const c = confetti.create(undefined, {
    resize: true,
    useWorker: false
  });

  return c({
    particleCount: 150,
    spread: 60,
    zIndex: 2000,
  });
}
