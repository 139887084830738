import { checkIsVideo } from '@features/custom-dashboard/utils/dashboard-utils';
import { MEDIA_TITLE_HEIGHT } from '@features/custom-dashboard/utils/media-utils';
import { GridDashboardMediaItem, UploadedMediaFile } from '@g17eco/types/insight-custom-dashboard';
import {
  getLatestActualUtrvs,
  getSumOfVariables,
  getVariablesWithValues,
} from '@routes/custom-dashboard/items/charts/multi-utrs-chart/utils';
import { ChartUtrData } from '@routes/custom-dashboard/items/types';
import BeginnerRating from '../../../images/wfn-rating/Beginner.svg';
import BronzeRating from '../../../images/wfn-rating/Bronze.svg';
import SilverRating from '../../../images/wfn-rating/Silver.svg';
import GoldRating from '../../../images/wfn-rating/Gold.svg';

interface Props {
  item: GridDashboardMediaItem;
  isEditing: boolean;
  utrsData: ChartUtrData[];
}

const getRatingImage = (rating: number) => {
  if (rating >= 76) {
    return GoldRating;
  }

  if (rating >= 51) {
    return SilverRating;
  }

  if (rating >= 26) {
    return BronzeRating;
  }

  return BeginnerRating;
};

const getFile = ({
  item,
  utrsData,
}: Required<Pick<Props, 'utrsData' | 'item'>>):
  | (Pick<UploadedMediaFile, 'name' | 'url' | 'type'> & { objectFit?: 'contain' })
  | undefined => {
  const { variables, calculation } = item;
  if (!variables || !calculation) {
    return item.files?.[0];
  }

  // Special case for Workforce Nutrition dashboard.
  const { latestActualUtrvs = [] } = getLatestActualUtrvs(utrsData);
  const variablesWithValues = getVariablesWithValues({ utrsData, variables, utrvs: latestActualUtrvs });
  const rating = getSumOfVariables(variablesWithValues);

  return { name: 'WORKFORCE-NUTRITION-RATING', url: getRatingImage(rating), type: 'image/svg', objectFit: 'contain' };
};

export const MediaWidget = ({ item, isEditing, utrsData }: Props) => {
  const className = isEditing || item.config?.hideBorder ? '' : 'border border-ThemeBorderDefault border-1 rounded-1';
  // Support showing 1 file for now.
  const file = getFile({ item, utrsData });
  if (!file) {
    return null;
  }

  return (
    <div className={`${className} w-100 h-100 d-flex flex-column`}>
      {checkIsVideo(file) ? (
        <video className='w-100 flex-grow-1' style={{ minHeight: 0 }} key={file.name} src={file.url} controls />
      ) : (
        <img
          style={{ objectFit: file.objectFit ?? 'cover', objectPosition: 'center', minHeight: 0 }}
          className='w-100 flex-grow-1'
          key={file.name}
          src={file.url}
          alt={file.name}
        />
      )}
      {item.title ? (
        <div
          className='text-ThemeHeadingDark background-ThemeBgExtralight px-3 d-flex align-items-center'
          style={{ height: `${MEDIA_TITLE_HEIGHT}px` }}
        >
          <span className='text-truncate'>{item.title}</span>
        </div>
      ) : null}
    </div>
  );
};
