import {
  AlignmentType,
  Document,
  Header,
  ImageRun,
  PageOrientation,
  Paragraph,
  SectionType,
  Table,
  TableCell,
  TableRow,
} from 'docx';
import { blueStyles, numberingStyles } from '../styles';
import {
  arrayToTableRow,
  footer,
  getBorders,
  heading,
  heading2,
  heading3,
  heading4,
  imageWrapper,
  pagebreak,
  paragraph,
  paragraphWithBoldText,
  paragraphWithMultiBoldText,
  spacedParagraph,
  spacer,
  infoParagraph as commonInfoParagraph
} from '../document-structure';
import { formatDateUTC } from '../../../utils/date';
import { SurveyInitiative } from '../../../types/survey';
import { DataSource, DataSources } from '.';
import { HistoricalReportData, ReportData } from '../../../types/reportData';
import {
  defaultPrivateQuestionText,
  getGovernanceData,
  getReportUtrvValue,
  getSustainabilityData,
  shouldDisplayPrivateText
} from '../reportData';
import { EnergyConsumptionDataSource } from './data/EnergyConsumption';
import { WaterConsumptionDataSource } from './data/WaterConsumption';
import { SurveyModelMinimalUtrv } from '../../../model/surveyData';
import { getLatestNote, simpleTable } from '../group-builder';
import { generateScopeGroups, generateSGXQuestions } from './sgx-renderer';
import { VisibilityStatus } from '../../../types/download';
import { SelectedGroup } from '../../downloads/util/downloadReportHandler';
import { QUESTION } from '@constants/terminology';
import { SafeTextRun } from '@utils/docx';

/** The order is important for these codes, determines the order in output table **/
const ENVIRONMENTAL_CODE = [
  'sgx-core-1a',
  'gri/2020/305-1/a',
  'gri/2020/305-2/a',
  'gri/2020/305-2/b',
  'gri/2020/305-3/a',
  'sgx-custom-56',
  'sgx-custom-57',
  'sgx-custom-58',
  'sgx-custom-60',
  'gri/2020/302-1/e',
  'gri/2020/302-3/a',
  'gri/2020/303-5/a',
  'survey/generic/water-intensity',
  'gri/2020/306-3/a',
];

const SOCIAL_CODE = [
  'gri/2020/405-1/b',
  'gri/2020/401-1/a',
  'gri/2020/401-1/b',
  'gri/2020/102-8/a',
  'gri/2020/404-1/a',
  'gri/2020/403-9/a',
  'gri/2020/403-10/a',
];

const GOVERNANCE_CODE = [
  'sgx-custom-42',
  'gri/2020/102-22',
  'gri/2020/405-1/a',
  'gri/2020/205-1/b',
  'gri/2020/205-2/b',
  'gri/2020/205-3/a',
  'gri/2020/205-2/e',
  'sgx-custom-48',
  'sgx-custom-49',
  'sgx-custom-53',
  'gri/2020/102-56/b',
  'sgx-core-28x',
  'sgx-custom-75',
  'sgx-core-28y',
  'sgx-core-28b',
];

const ENERGY_CONSUMPTION_PARAGRAPH = [
  { text: 'Energy consumption ', style: 'bold' },
  { text: 'is the amount of energy or power used, whilst ' },
  { text: 'energy Intensity ', style: 'bold' },
  {
    text: 'is measured by the quantity of energy required per unit output or activity, so that using less energy to produce a product reduces the intensity.',
  },
]

const WATER_CONSUMPTION_PARAGRAPH = [
  { text: 'Water consumption ', style: 'bold' },
  { text: 'is the portion of water use that is not returned to the original water source, whilst ' },
  { text: 'Intensity ', style: 'bold' },
  {
    text: 'calculates only the intensity of total water intake of the overhead and production process.',
  },
]

type AssuranceSummaryType = { [key: string]: string }[] | undefined | string;

const FONT_SIZE_SMALL = 18;
const H1_SIZE = 120
const TEXT_RUN_SMALL = {
  textRun: {
    size: FONT_SIZE_SMALL
  }
};

const TEXT_ROW_STYLES = {
  ...TEXT_RUN_SMALL,
  alignment: AlignmentType.CENTER,
};

export const STYLES = {
  TABLE_WIDTH: 9000,
  TABLE_WIDTH_LANDSCAPE: 13900,
  TABLE_HEADER_SHADING: {
    fill: '5189BD'
  },
  TABLE_BORDER_COLOUR: 'DDDDDD',
  LINE_SPACING: 300
};

const MARGINS_MD = {
  left: 150,
  right: 150,
  top: 150,
  bottom: 150,
};

export const paddedTableCell = (paragraph: Paragraph) => new TableCell({
  children: [paragraph],
  margins: {
    left: MARGINS_MD.left,
    right: MARGINS_MD.right,
  }
});

const infoParagraph = (text: string, size: number = 24) => commonInfoParagraph(text, { textRun: { size } });

const getChart = (dataSource: DataSource) => {
  if (!dataSource.chart) {
    return paragraph('No data');
  }

  return new ImageRun({
    data: Uint8Array.from(atob(dataSource.chart), c => c.charCodeAt(0)),
    transformation: {
      width: dataSource.width ?? 100,
      height: dataSource.height ?? 100,
    }
  });
}

/**
 * This just select last note, no reason to look at history?
 * @link getLatestNote
 */
export const getComments = (reportData: ReportData[], utrCode: string, visibility: VisibilityStatus, emptyText: string = 'No further explanation provided.') => {
  const utrv = reportData.find(d => d.universalTracker.code === utrCode);

  if (!utrv) {
    return emptyText;
  }

  if (shouldDisplayPrivateText(utrv, visibility)) {
    return defaultPrivateQuestionText;
  }

  return getLatestNote(utrv, emptyText)
}

export const HEADER_ROW_STYLES = {
  shading: STYLES.TABLE_HEADER_SHADING,
  margins: MARGINS_MD,
  borders: getBorders(STYLES.TABLE_BORDER_COLOUR),
  style: 'plain',
  textRun: {
    color:  'FFFFFF',
    size: 20,
  }
}

enum ExternalAssurance {
  Reference_Report = 'reference_report',
  Relationship = 'relationship',
  HighestGovernance = 'highest_governance_body',
}

export interface ScopeGroupHistoricalData extends SelectedGroup {
  questionData: HistoricalReportData[]
}

export interface SGXMetricReportGeneratorParams {
  survey: SurveyInitiative;

  /** Chart data sources **/
  dataSources: DataSources;

  /** Historical data **/
  questionData: HistoricalReportData[];

  /** Historical data for included scope packs **/
  scopeGroupHistoricalData: ScopeGroupHistoricalData[];

  /** Latest available target for questionData utrvs **/
  targets: SurveyModelMinimalUtrv[];

  visibilityStatus: VisibilityStatus;

  displayUserInput?: boolean,
}

export const SGXMetricReportGenerator = async (params: SGXMetricReportGeneratorParams): Promise<Document> => {

  const {
    survey,
    dataSources,
    questionData,
    scopeGroupHistoricalData,
    targets,
    visibilityStatus,
    displayUserInput = false,
  } = params

  const periodCovered = formatDateUTC(survey.effectiveDate, 'MMMM YYYY');

  const targetMap = new Map(targets.map(target => [target.universalTrackerId, target]))

  // Last report data
  const reportData = questionData[0]?.reportData ?? [];

    /** Only exclude mandatory sgx_metrics, as those already exposes through ESG tables **/
    const excludeScopeGroupCodes = ['sgx_metrics'];
    const preferredAltCodes = ['sgx_metrics', 'sgx_extended'];
    const nonSGXScopeGroupData = scopeGroupHistoricalData.filter((data) => !excludeScopeGroupCodes.includes(data.code));

    const listOfSGXQuestions = (type: string, utrCodes: string[]) => generateSGXQuestions({
      headerText: `SGX CORE ${type} ${QUESTION.UPPERCASE_PLURAL}`,
      questionData,
      targetMap,
      preferredAltCodes,
      utrCodes,
      visibilityStatus,
      displayUserInput,
    });

  const contentsPage = [
    heading2('CONTENTS'),
    spacer(),
    heading3('INTRODUCTION'),
    heading4('  IDENTIFICATION OF MATERIAL ESG FACTORS'),
    heading4('  MATERIAL ESG FACTORS'),
    heading4('  STAKEHOLDER ENGAGEMENT'),
    heading4('  BOARD STATEMENT'),
    spacer(),
    heading3('ENVIRONMENTAL'),
    heading4('  GREENHOUSE GAS ABSOLUTE EMISSIONS'),
    heading4('  EMISSION INTENSITY'),
    heading4('  ENERGY CONSUMPTION AND INTENSITY'),
    heading4('  WATER CONSUMPTION AND INTENSITY'),
    spacer(),
    heading3('SOCIAL'),
    heading4('  GENDER DIVERSITY'),
    spacer(),
    heading3('GOVERNANCE'),
    heading4('  BOARD INDEPENDENCE'),
    heading4('  FEMALE BOARD MEMBERS'),
    heading4('  ASSURANCE OF SUSTAINABILITY REPORT'),
    spacer(),
    heading3('ADDITIONAL SCOPE PACK SUBMISSIONS'),
    ...nonSGXScopeGroupData.map((pack, index) => heading4(`  ${pack.name}`)),
    spacer(),
  ];

  const defaultEmptyText = 'No response submitted';

  const getHeaderRow = (cols: string[]) => {
    return cols.map(t => new Paragraph({
      children: [
        new SafeTextRun({
          text: t,
          color: 'FFFFFF',
          size: 24
        })
      ],
      alignment: AlignmentType.CENTER
    }));
  }

  const introductionTable = () => {
    const code = 'sgx-custom-75';
    const data = getSustainabilityData(reportData, code, visibilityStatus);
    const utrv = reportData.find((d) => d.universalTracker.code === code);

    if (!Array.isArray(data)) {
      return paragraph(data ?? defaultEmptyText);
    }

    if (data.length === 0 || !utrv) {
      return paragraph(defaultEmptyText);
    }

    const columns = utrv.universalTracker.valueValidation?.table?.columns;
    if (!columns) {
      return paragraph(defaultEmptyText);
    }

    const { codes, names } = columns.reduce((acc, c) => {
      acc.codes.push(c.code);
      acc.names.push(c.name);
      return acc;
    }, { codes: [] as string[], names: [] as string[] })

    return simpleTable([
      arrayToTableRow(getHeaderRow(names), HEADER_ROW_STYLES),
      ...(data.map((row: { [key: string]: string }) =>
        new TableRow({
          children: codes.map(code => paddedTableCell(paragraph(row[code]))),
        })
      )),
    ], STYLES.TABLE_WIDTH);
  };

  const governanceTable = () => {
    const data = getGovernanceData(reportData, 'sgx-custom-42', visibilityStatus);

    const rows = data ? data.map((row: { name: string; value: string }) =>
      new TableRow({
        children: [
          paddedTableCell(paragraph(row.name, TEXT_RUN_SMALL)),
          paddedTableCell(paragraph(row.value || defaultEmptyText, TEXT_ROW_STYLES)),
        ],
      })
    ) : [];

    return simpleTable(rows, STYLES.TABLE_WIDTH, [2,1]);
  };

  // Codes are not aligning with question names
  const overviewPage = [
    heading2('INTRODUCTION'),
    // 28A)
    heading3('Identification of material ESG factors'),
    paragraph(getReportUtrvValue(reportData, 'sgx-core-28x', visibilityStatus) || defaultEmptyText),
    // 28B)
    heading3('Material ESG factors'),
    introductionTable(),
    // 28C)
    heading3('Stakeholder engagement'),
    paragraph(getReportUtrvValue(reportData, 'sgx-core-28y', visibilityStatus) || defaultEmptyText),
    // 28D)
    heading3('Board Statement'),
    paragraph(getReportUtrvValue(reportData, 'sgx-core-28b', visibilityStatus) || defaultEmptyText),
  ];

  const ghgEmissionsNotes = () => {
    const scopeNotes: {[key: string]: Paragraph[]} = {scope1: [], scope2: [], scope3: []}

    dataSources.ghgEmissions.rows?.forEach(d => {
      if (d.notes?.scope1) {
        scopeNotes.scope1.push(new Paragraph(`${d.date} ${d.notes.scope1}`))
      }
      if (d.notes?.scope2) {
        scopeNotes.scope2.push(new Paragraph(`${d.date} ${d.notes.scope2}`))
      }
      if (d.notes?.scope3) {
        scopeNotes.scope3.push(new Paragraph(`${d.date} ${d.notes.scope3}`))
      }
    })

    if(scopeNotes.scope1.length > 0) {
      scopeNotes.scope1.unshift(paragraph('Absolute emissions: Scope 1 Notes', { style: 'bold' }))
    }
    if(scopeNotes.scope2.length > 0) {
      scopeNotes.scope2.unshift(paragraph('Absolute emissions: Scope 2 Notes', { style: 'bold' }))
    }
    if(scopeNotes.scope3.length > 0) {
      scopeNotes.scope3.unshift(paragraph('Absolute emissions: Scope 3 Notes', { style: 'bold' }))
    }

    return [...scopeNotes.scope1, ...scopeNotes.scope2, ...scopeNotes.scope3]
  }

  const ghgEmissionsIntensityNotes = () => {
    const scopeNotes: {scope1: Paragraph[], scope2: Paragraph[], scope3: Paragraph[]} = {scope1:[], scope2: [], scope3: []}

    dataSources.ghgEmissionsIntensity.rows?.forEach(d => {
      if (d.notes?.scope1) {
        scopeNotes.scope1.push(new Paragraph(`${d.date}: ${d.notes.scope1}`))
      }
      if (d.notes?.scope2) {
        scopeNotes.scope2.push(new Paragraph(`${d.date}: ${d.notes.scope2}`))
      }
      if (d.notes?.scope3) {
        scopeNotes.scope3.push(new Paragraph(`${d.date}: ${d.notes.scope3}`))
      }
    })

    if(scopeNotes.scope1.length > 0) {
      scopeNotes.scope1.unshift(paragraph('Emission Intensity: Scope 1 Notes', { style: 'bold' }))
    }
    if(scopeNotes.scope2.length > 0) {
      scopeNotes.scope2.unshift(paragraph('Emission Intensity: Scope 2 Notes', { style: 'bold' }))
    }
    if(scopeNotes.scope3.length > 0) {
      scopeNotes.scope3.unshift(paragraph('Emission Intensity: Scope 3 Notes', { style: 'bold' }))
    }

    return [...scopeNotes.scope1, ...scopeNotes.scope2, ...scopeNotes.scope3]
  }

  const consumptionNotes = (datasource: EnergyConsumptionDataSource | WaterConsumptionDataSource, headingPrefix: string) => {
    const notes: {[key :string]: Paragraph[]} = {consumption: [], intensity: []}

    datasource.rows?.forEach(d => {
      if (d.notes?.consumption) {
        notes.consumption.push(new Paragraph(`${d.date}: ${d.notes.consumption}`))
      }
      if (d.notes?.intensity) {
        notes.intensity.push(new Paragraph(`${d.date}: ${d.notes.intensity}`))
      }
    })

    if(notes.consumption.length > 0) {
      notes.consumption.unshift(paragraph(`${headingPrefix} consumption Notes`, { style: 'bold' }))
    }
    if(notes.intensity.length > 0) {
      notes.intensity.unshift(paragraph(`${headingPrefix} intensity Notes`, { style: 'bold' }))
    }

    return [...notes.consumption, ...notes.intensity]
  }


  const socialNotes = () => {
    const notes: Paragraph[] = []

    dataSources.social.rows?.forEach(d => {
      if (d.notes) {
        notes.push(new Paragraph(`${d.date}: ${d.notes}`))
      }
    })

    if(notes.length > 0) {
      notes.unshift(paragraph('Notes', { style: 'bold' }))
    }

    return notes
  }


  const boardNotes = () => {
    const notes: {[key :string]: Paragraph[]} = {board_independence: [], pc_female_boardmembers: []}

    dataSources.governance.rows?.forEach(d => {
      if (d.notes?.board_independence) {
        notes.board_independence.push(new Paragraph(`${d.date}: ${d.notes.board_independence}`))
      }
      if (d.notes?.pc_female_boardmembers) {
        notes.pc_female_boardmembers.push(new Paragraph(`${d.date}: ${d.notes.pc_female_boardmembers}`))
      }
    })

    if(notes.board_independence.length > 0) {
      notes.board_independence.unshift(paragraph('Board independence notes', { style: 'bold' }))
    }
    if(notes.pc_female_boardmembers.length > 0) {
      notes.pc_female_boardmembers.unshift(paragraph('% females board members notes', { style: 'bold' }))
    }

    return [...notes.board_independence, ...notes.pc_female_boardmembers]
  }


  const emissionsPage = [
    heading2('ENVIRONMENTAL'),
    heading3('GREENHOUSE GAS ABSOLUTE EMISSIONS'),
    paragraphWithBoldText('Greenhouse gas emissions', ' from human activities strengthen the greenhouse effect, causing climate change. Most is carbon dioxide from burning fossil fuels: coal, oil, and natural gas.', ''),
    imageWrapper(getChart(dataSources.ghgEmissions), { alignment: AlignmentType.CENTER }),
    ...ghgEmissionsNotes(),
    spacer(),
    infoParagraph('Scope 1 - the emissions that a company makes directly e.g. company vehicles, boilers.', FONT_SIZE_SMALL),
    infoParagraph('Scope 2 - The emissions a company makes indirectly e.g. energy purchase, such as electricity.', FONT_SIZE_SMALL),
    infoParagraph(`Scope 3 - the emissions that are associated with the organisation e.g.
    business related travel and energy use of staying in hotels, emissions related to purchased goods from suppliers, staff commuting.`, FONT_SIZE_SMALL),
    spacer(),
    heading3('EMISSION INTENSITY'),
    paragraphWithBoldText('Emission intensity', ' is the volume of emissions per unit of GDP. Reducing emission intensity means that less pollution is being created per unit of GDP.', ''),
    imageWrapper(getChart(dataSources.ghgEmissionsIntensity), { alignment: AlignmentType.CENTER }),
    spacer(),
    ...ghgEmissionsIntensityNotes(),
    heading3('ENERGY CONSUMPTION AND INTENSITY'),
    paragraphWithMultiBoldText(ENERGY_CONSUMPTION_PARAGRAPH),
    dataSources.energyConsumption.rows?.length
      ? imageWrapper(getChart(dataSources.energyConsumption), { alignment: AlignmentType.CENTER })
    : spacedParagraph('No data'),
    ...consumptionNotes(dataSources.energyConsumption, 'Energy'),
    spacer(),
    heading3('WATER CONSUMPTION AND INTENSITY'),
    paragraphWithMultiBoldText(WATER_CONSUMPTION_PARAGRAPH),
    imageWrapper(getChart(dataSources.waterConsumption), { alignment: AlignmentType.CENTER }),
    ...consumptionNotes(dataSources.waterConsumption, 'Water'),
  ];

  const socialPage = [
    heading2('SOCIAL'),
    heading3('GENDER DIVERSITY'),
    paragraphWithBoldText('Gender diversity', ' in a workplace means that men and women are hired at a comparable rate, paid evenly, and given the same working opportunities with equal promotions. Women account for c.40% of the global workforce, yet only c.5% of those are in upper management positions.', ''),
    imageWrapper(getChart(dataSources.social), { alignment: AlignmentType.CENTER }),
    ...socialNotes(),
  ]

  const governancePage = [
    heading2('GOVERNANCE'),
    heading3('BOARD INDEPENDENCE'),
    paragraphWithBoldText('Board independence', ' occurs when a board member has not been and is not currently employed by the company or its auditor and the board member\'s employer doesn\'t do a significant amount of business with the company.'),
    governanceTable(),
    heading3('FEMALE BOARD MEMBERS'),
    paragraphWithBoldText('Board diversity', ' aims to cultivate a broad spectrum of demographic attributes and characteristics in the boardroom. A simple and common measure to promote heterogeneity in the boardroom – commonly known as gender diversity – is to include female representation on the board.', ''),
    imageWrapper(getChart(dataSources.governance), { alignment: AlignmentType.CENTER }),
    ...boardNotes(),
  ]

  const assuranceSummary: AssuranceSummaryType = getSustainabilityData(reportData, 'sgx-custom-53', visibilityStatus);
  const externalAssurances = getReportUtrvValue(reportData, 'gri/2020/102-56/b', visibilityStatus);

  const getExternalAnswer = (type: string) => {
    switch (type) {
      case ExternalAssurance.Reference_Report:
        return {
          title: 'Reference to the external assurance report, statements, or opinions',
          answer: externalAssurances?.[ExternalAssurance.Reference_Report],
        };
      case ExternalAssurance.Relationship:
        return {
          title: 'Relationship to Assurer',
          answer: externalAssurances?.[ExternalAssurance.Relationship],
        };
      default:
        return {
          title: 'How senior executives are involved in external assurance',
          answer: externalAssurances?.[ExternalAssurance.HighestGovernance],
        };
    }
  };

  const externalAssuranceContent = (type: string) => {
    const externalAssurance: { title: string; answer: string } = getExternalAnswer(type);
    return [paragraphWithBoldText(externalAssurance.title, '', ''), paragraph(externalAssurance.answer)];
  };

  const getExternalAssurances = () =>
    typeof externalAssurances === 'object'
      ? Object.keys(externalAssurances).map((item) => externalAssuranceContent(item)).flat()
      : [];

  const getAssuranceSummary = (property: string) =>
    typeof assuranceSummary === 'string' ? assuranceSummary : assuranceSummary?.[0]?.[property];

  const assuranceOfSustainabilityPage = [
    heading3('Assurance of sustainability report'),
    paragraphWithBoldText('Level of Assurance - ', getAssuranceSummary('sgx53-assurance') || defaultEmptyText, ''),
    paragraph(getAssuranceSummary('sgx53-describe') || ''),
    ...getExternalAssurances(),
  ]

  const graphPage = (children: (Paragraph | Table)[]) => {
    return {
      properties: {
        type: SectionType.NEXT_PAGE,
      },
      headers: {
        default: new Header({
          children: [
            new Paragraph({
              text: 'SUSTAINABILITY REPORT',
              alignment: AlignmentType.LEFT,
            }),
          ],
        }),
      },
      footers: {
        default: footer(),
      },
      children,
    };
  };

  const tablePage = (children: (Paragraph | Table)[]) => {
    return {
      properties: {
        type: SectionType.NEXT_PAGE,
        page: {
          size: {
            orientation: PageOrientation.LANDSCAPE,
          },
        },
      },
      headers: {
        default: new Header({
          children: [
            new Paragraph({
              text: 'SUSTAINABILITY REPORT',
              alignment: AlignmentType.LEFT,
            }),
          ],
        }),
      },
      footers: {
        default: footer(),
      },
      children,
    };
  };

  const document = new Document({
    styles: {
      paragraphStyles: blueStyles
    },
    numbering: {
      config: numberingStyles
    },
    sections: [
      {
        properties: { type: SectionType.NEXT_PAGE, },
        headers: { default: new Header({ children: [] }), },
        footers: { default: footer() },
        children: [
          new Paragraph({
            text: '',
            spacing: {
              before: 7000
            }
          }),
          heading2(periodCovered),
          heading2(survey.initiative.name),
          heading('SUSTAINABILITY REPORT', { textRun: { size: H1_SIZE } }),
        ],
      },
      graphPage([...contentsPage, pagebreak(), ...overviewPage, pagebreak(), ...emissionsPage]),
      tablePage(listOfSGXQuestions('ENVIRONMENTAL', ENVIRONMENTAL_CODE)),
      graphPage([...socialPage]),
      tablePage(listOfSGXQuestions('SOCIAL', SOCIAL_CODE)),
      graphPage([...governancePage, pagebreak(), ...assuranceOfSustainabilityPage]),
      tablePage([
        ...listOfSGXQuestions('GOVERNANCE', GOVERNANCE_CODE),
        pagebreak(),
        ...generateScopeGroups({
          headerText: 'ADDITIONAL SCOPE PACK SUBMISSIONS',
          nonSGXScopeGroupData: nonSGXScopeGroupData,
          targetMap: targetMap,
          visibilityStatus,
          displayUserInput: true,
        }),
      ]),
    ],
  });

  return document;
}
