import { DashboardItemFilters } from '@api/insights';
import { GridItemProps } from '@routes/custom-dashboard/GridItem';
import { ChartData, QuestionData } from '@routes/custom-dashboard/utils';
import {
  CalculationValue,
  ChartSubType,
  GridDashboardChartItem,
  GridDashboardChartItemBase,
  GridDashboardIntegrationItem,
  GridDashboardIntegrationItemBase,
  GridDashboardItem,
  GridDashboardItemBase,
  GridDashboardItemCreate,
  GridDashboardMediaItem,
  GridDashboardMediaItemBase,
  GridDashboardSDGChartItem,
  GridDashboardSDGChartItemBase,
  GridDashboardSpaceItem,
  GridDashboardSpaceItemBase,
  GridDashboardTableItem,
  GridDashboardTableItemBase,
  GridDashboardTempChartItem,
  GridDashboardTempItem,
  GridDashboardTextItem,
  GridDashboardTextItemBase,
  InsightDashboardItemType,
  InsightDashboardTempItemType,
} from '@g17eco/types/insight-custom-dashboard';
import { UniversalTrackerBlueprintMin } from '../../types/universalTracker';
import { QUESTION } from '@constants/terminology';
import { ExtendedUtrVariable } from '@routes/custom-dashboard/types';
import { UtrVariable } from '@routes/summary/insights/utils/constants';
import { ChartUtrData } from '@routes/custom-dashboard/items/types';

export type EditingDashboardItem = GridDashboardItemBase | GridDashboardItem | GridDashboardTempItem;

export type ChartItem =
  | GridDashboardTempChartItem
  | GridDashboardIntegrationItem
  | GridDashboardIntegrationItemBase
  | GridDashboardChartItem
  | GridDashboardChartItemBase
  | GridDashboardSDGChartItem
  | GridDashboardSDGChartItemBase;

export type TableItem = GridDashboardTableItem | GridDashboardTableItemBase;

export type MediaItem = GridDashboardMediaItem | GridDashboardMediaItemBase;

export type TextItem = GridDashboardTextItem | GridDashboardTextItemBase;

export type SpaceItem = GridDashboardSpaceItem | GridDashboardSpaceItemBase;

export const isChartItem = (item: EditingDashboardItem): item is ChartItem => {
  return [
    InsightDashboardItemType.Chart,
    InsightDashboardItemType.SDGTracker,
    InsightDashboardItemType.Integration,
    InsightDashboardTempItemType.TempChart,
  ].includes(item.type);
};

export interface ItemEditorProps extends Pick<GridItemProps, 'survey' | 'integrationsData'> {
  editingItem: EditingDashboardItem | undefined;
  updateItem: (updatingItem: GridDashboardItemCreate) => Promise<void>;
  handleCancel: () => void;
  initiativeId: string;
  queryParams: DashboardItemFilters;
}

export interface ChartEditorProps extends ItemEditorProps {
  editingItem: ChartItem;
}

export interface TableEditorProps extends ItemEditorProps {
  editingItem: TableItem;
}

export interface MediaEditorProps extends Pick<ItemEditorProps, 'editingItem' | 'updateItem' | 'handleCancel'> {
  editingItem: MediaItem;
}

export interface TextEditorProps
  extends Pick<ItemEditorProps, 'editingItem' | 'updateItem' | 'handleCancel' | 'initiativeId'> {
  editingItem: TextItem;
}

export interface SpaceEditorProps extends Pick<ItemEditorProps, 'editingItem' | 'updateItem' | 'handleCancel'> {
  editingItem: SpaceItem;
}

export type QuestionsMapUtr = Pick<UniversalTrackerBlueprintMin, 'valueLabel' | 'valueType' | 'valueValidation'>;
export type ExtendedQuestionsMapUtr = QuestionsMapUtr & Pick<UniversalTrackerBlueprintMin, 'unit' | 'unitType' | 'numberScale'>;

export interface MetricSelectorProps extends ChartEditorProps {
  chartData: ChartData;
  changeChartData: (newChartData: Partial<ChartData>) => void;
  questionsMap: Map<string, ExtendedQuestionsMapUtr>;
}

export const CHART_SUBTYPE = {
  [ChartSubType.SingleValue]: `Single ${QUESTION.SINGULAR}`,
  [ChartSubType.SparkLine]: `Single ${QUESTION.SINGULAR} with sparkline`,
  [ChartSubType.Line]: 'Timeline',
  [ChartSubType.Column]: 'Vertical bar diagram',
  [ChartSubType.Bar]: 'Horizontal bar diagram',
  [ChartSubType.FullPie]: 'Pie chart',
  [ChartSubType.Pie]: 'Donut chart',
};

export type PreviewChartData = Required<Pick<ChartData, 'subType'>> & {
  metrics: ExtendedUtrVariable[];
  type: InsightDashboardItemType.Chart | InsightDashboardItemType.SDGTracker | InsightDashboardItemType.Integration;
};

export interface HistoricalUtrsState {
  historicalUtrs: ChartUtrData[];
  isLoading: boolean;
  errorMessage: string;
}

export interface TableRowData {
  variables: UtrVariable[];
  value: CalculationValue;
}

export type TableRowDataInfo = {
  id: string;
  data: TableRowData;
};

export type TableData = {
  rowData: TableRowDataInfo[];
  editRowId: string;
};

export type TextData = Required<Pick<GridDashboardTextItem, 'type' | 'subType'>> & {
  text: string;
  metric: QuestionData;
};
