import { useToggle } from '@hooks/useToggle';

interface Props {
  initiativeId?: string;
}

export const useSettingsSidebar = ({ initiativeId }: Props) => {
  const [openSettingsSidebar, toggleSettingsSidebar] = useToggle(false);
  const handleAddNew = () => {
    if (!initiativeId) {
      return;
    }
    toggleSettingsSidebar();
  };

  return {
    openSettingsSidebar,
    toggleSettingsSidebar,
    handleAddNew
  }
}