import { TagContainer, TagContainerProps } from '@components/tags/TagContainer';
import { MetricOverrideContainer, MetricOverrideContainerProps } from '@features/question-configuration';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { getModalTitle, Tabs } from '../utils';
import SimpleTooltip from '@components/simple-tooltip';
import { QUESTION, SURVEY } from '@constants/terminology';

type TabProps = {
  tabs: { code: Tabs; disabled?: boolean; tooltip?: string }[];
  activeTab: Tabs | undefined;
  setActiveTab: (tab: Tabs | undefined) => void;
};

export type QuestionConfigurationModalProps = TagContainerProps & Omit<MetricOverrideContainerProps, 'handleCloseModal'>;

type Props = QuestionConfigurationModalProps & TabProps;

export const getDescription = (tab: Tabs | undefined, isMultipleQuestionSelected: boolean) => {
  switch (tab) {
    case Tabs.InputOverrides: {
      const description =
        'Overrides are set globally. ' +
        `All newly created ${SURVEY.PLURAL}, as well as any currently unanswered ${QUESTION.PLURAL} will respect these rules.`;
      if (!isMultipleQuestionSelected) {
        return description;
      }
      return (
        <div>
          {description}
          <div className='mt-1'>
            {`You have selected multiple ${QUESTION.PLURAL}. ` +
              `Changing the unit, number scale or decimal scale will impact all ${QUESTION.PLURAL} selected.`}
          </div>
        </div>
      );
    }
    case Tabs.Tags:
      return (
        'Tags are set globally. Any reports created in the future  will be tagged with the below. ' +
        'Metrics already added to scope will not update their tags'
      );
    default:
      return '';
  }
};

export const QuestionConfigurationModal = (props: Props) => {
  const { tabs, activeTab, setActiveTab, handleReload: onReload, ...modalContentProps } = props;

  if (!activeTab) {
    return null;
  }

  const onToggle = () => {
    setActiveTab(undefined);
  };

  const handleReload = async (props?: { reloadSurvey?: boolean, closeModal?: boolean }) => {
    if (props?.closeModal) {
      onToggle();
    }
    onReload(props);
  };

  const CurrentTabContent = () => {
    if (!activeTab) {
      return null;
    }
    switch (activeTab) {
      case Tabs.MetricOverrides:
        return <></>;
      case Tabs.InputOverrides:
        return <MetricOverrideContainer {...modalContentProps} handleReload={handleReload} handleCloseModal={onToggle} />;
      case Tabs.Tags:
        return <TagContainer {...modalContentProps} handleReload={handleReload} />;
      default:
        return null;
    }
  };

  const isMultipleQuestionSelected = modalContentProps.selectedQuestions.length > 1;

  return (
    <Modal isOpen={!!activeTab} toggle={onToggle} backdrop='static'>
      <ModalHeader toggle={onToggle}>{getModalTitle(modalContentProps.selectedQuestions)}</ModalHeader>
      <ModalBody className='pb-0'>
        <div className='mb-3 d-flex justify-content-between align-items-center gap-2'>
          {tabs.map(({ code, tooltip, disabled }) => (
            <SimpleTooltip key={code} className='w-100' text={tooltip}>
              <Button
                className='w-100'
                color='primary'
                outline={!disabled && activeTab !== code}
                onClick={() => setActiveTab(code)}
                active={activeTab === code}
                disabled={disabled}
              >
                {code}
              </Button>
            </SimpleTooltip>
          ))}
        </div>
        <div>{getDescription(activeTab, isMultipleQuestionSelected)}</div>
      </ModalBody>
      <CurrentTabContent />
    </Modal>
  );
};
