import { useContext, useState } from 'react';
import { Button } from 'reactstrap';
import { QuestionConfigurationModal, QuestionConfigurationModalProps } from './modal/QuestionConfigurationModal';
import { InputOverrideType, NON_NUMERIC_SELECTED_QUESTION_TOOLTIP, Tabs } from './utils';
import { QuestionManagementContext } from '../../apps/company-tracker/components/admin-dashboard/questions/QuestionManagementContainer';

interface Props {
  modalProps: QuestionConfigurationModalProps;
  name: Tabs;
  disabled?: boolean;
  hiddenTabs?: Tabs[];
}

export const QuestionConfigurationButton = (props: Props) => {
  const { name, disabled = false, hiddenTabs = [], modalProps } = props;
  const { setInputOverrideType } = useContext(QuestionManagementContext);

  const [tab, setTab] = useState<Tabs | undefined>(undefined);

  const setActiveTab = (tab: Tabs | undefined) => {
    setTab(tab);
  };

  const canOverrideMetrics = modalProps.numericSelectedQuestions.length > 0;

  const tabs = [
    {
      code: Tabs.MetricOverrides,
      disabled: true,
    },
    ...(hiddenTabs.includes(Tabs.InputOverrides)
      ? []
      : [
          {
            code: Tabs.InputOverrides,
            disabled: !canOverrideMetrics,
            tooltip: canOverrideMetrics ? '' : NON_NUMERIC_SELECTED_QUESTION_TOOLTIP,
          },
        ]),
    ...(hiddenTabs.includes(Tabs.Tags) ? [] : [{ code: Tabs.Tags }]),
  ];

  return (
    <>
      <Button
        className='my-1 ml-2'
        onClick={() => {
          setTab(name);
          setInputOverrideType(modalProps.selectedQuestions.length > 1 ? InputOverrideType.NumberScale : undefined);
        }}
        disabled={disabled}
        data-testid={name === Tabs.Tags ? 'bulk-action-add-tag-btn' : undefined}
      >
        {name}
      </Button>
      {tab ? (
        <QuestionConfigurationModal tabs={tabs} activeTab={tab} setActiveTab={setActiveTab} {...modalProps} />
      ) : null}
    </>
  );
};
