import { DashboardSection, DashboardSectionTitle } from '@components/dashboard';
import { IntegrationProvider, IntegrationProviderExtended } from '@g17eco/types/integration';
import { ProductCards } from '@routes/home/flavours/partials/ProductCards';

export interface ProviderCard extends IntegrationProvider {
  buttons?: { text: string; onClick: () => void }[];
}

export interface ProviderCardExtended extends IntegrationProviderExtended {
  buttons?: { text: string; onClick: () => void }[];
  onClick: () => void;
}

interface Props {
  cards: (ProviderCard | ProviderCardExtended)[];
  title: string;
}

export const IntegrationList = ({ cards, title }: Props) => {
  return (
    <>
      <DashboardSectionTitle title={title} />
      {cards.length ? (
        <DashboardSection>
          <ProductCards cards={cards} />
        </DashboardSection>
      ) : (
        <DashboardSection className='text-center text-ThemeTextMedium'>
          There are no {title.toLowerCase()} to display.
        </DashboardSection>
      )}
    </>
  );
};
