import { Toast } from 'react-hot-toast';
import './styles.scss';
import { ToastHeader } from './ToastHeader';
import { ToastBody } from './ToastBody';

export type ColorTheme = 'success' | 'danger' | 'warning' | 'primary' | 'secondary' | 'info';

export interface ButtonProps {
  text: string;
  handler: () => void;
  color?: ColorTheme;
  outline?: boolean;
  enforceRemove?: boolean;
}

export interface CustomToastProps {
  color: ColorTheme;
  currentToast?: Toast;
  title?: string | JSX.Element;
  icon?: string;
  hasDismissButton?: boolean;
  message?: string;
  buttonProps?: ButtonProps[];
  handleClose: (id?: string) => void;
  date?: Date;
}

export const CustomToast = (props: CustomToastProps) => {
  const { color = 'primary', currentToast } = props;

  return (
    <div
      className={`toast__container toast--${color}`}
      style={{
        opacity: currentToast?.visible ? 1 : 0,
        transition: 'opacity 100ms ease-in-out',
      }}
    >
      <ToastHeader {...props} />
      <ToastBody {...props} />
    </div>
  );
};
