import { PreviousUtrv } from '@features/assistant';
import { g17ecoApi, transformResponse } from './g17ecoApi';
import { AdditionalContext, AIResponse } from '@g17eco/types/ai';
import { UniversalTrackerValuePlain } from '@g17eco/types/surveyScope';

interface GetAIUtrvAssistantParams {
  utrvId: string;
  initiativeId: string;
  additionalContext?: AdditionalContext;
}

interface AIUtrvSuggestion {
  predictedAnswer?: string | number | { [key: string]: string | number | string[] };
  questionExplanation?: string;
  previousUtrvs: PreviousUtrv[];
  targetValue?: number;
}

interface GetAIUtrvFurtherNotesDraftParams {
  initiativeId: string;
  data: {
    utrvId: string;
    draftData: Pick<UniversalTrackerValuePlain, 'value' | 'unit' | 'numberScale' | 'valueData'>;
  };
}

const UTRV_ASSISTANT_TAG = 'ai-utrv-assistant';
const TAGS = [UTRV_ASSISTANT_TAG];

export const aiApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: TAGS,
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getAIUtrvAssistant: builder.query<AIUtrvSuggestion, GetAIUtrvAssistantParams>({
        transformResponse,
        query: ({ initiativeId, utrvId, additionalContext }) => {
          return {
            url: `/initiatives/${initiativeId}/ai/utrv-assistant/${utrvId}`,
            method: 'post',
            data: additionalContext
          };
        },
        providesTags: (_result, _error, agrs) => [{ type: UTRV_ASSISTANT_TAG, id: agrs.utrvId }],
      }),
      getAIUtrvFurtherNotesDraft: builder.mutation<AIResponse, GetAIUtrvFurtherNotesDraftParams>({
        transformResponse,
        query: ({ initiativeId, data }) => {
          return {
            url: `/initiatives/${initiativeId}/ai/generate-draft/further-notes`,
            method: 'post',
            data,
          };
        },
      }),
    }),
  });

export const { useGetAIUtrvAssistantQuery, useGetAIUtrvFurtherNotesDraftMutation } = aiApi;
