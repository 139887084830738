import Chart from 'react-google-charts';
import {
  CalculationType,
  ChartDataLoaded,
  ChartSubType,
  GridDashboardChartItem,
} from '../../../../../types/insight-custom-dashboard';
import { tryCalculation } from '@utils/formula';
import { chartStyleNames, getChartOptions } from '../../../../summary/insights/utils/chartStyles';
import { ChartType } from '../../../../summary/insights/utils/constants';
import { TakenFromText } from '../common/TakenFromText';
import { getLatestActualUtrvs, getVariablesWithValues } from './utils';
import { processCalculation } from '@services/aggregation/stageAggregation';
import { useEffect, useState } from 'react';
import { getLatestActualHistoricalUtrs } from '@services/aggregation/utrData';
import { ReactGoogleChartProps } from 'react-google-charts/dist/types';
import { ChartUtrData } from '../../types';

type Props = Pick<GridDashboardChartItem, 'calculation' | 'variables'> & {
  utrsData: ChartUtrData[];
  subType: ChartSubType.FullPie | ChartSubType.Pie | ChartSubType.PieWithLegend;
  overrideOptions?: ReactGoogleChartProps['options'];
};

type ChartColumn = string | number;
type ChartDataResponse = ChartDataLoaded<ChartColumn>;

const getChartData = async ({
  utrsData,
  variables,
  calculation,
}: Required<Omit<Props, 'subType' | 'overrideOptions'>>): Promise<ChartDataResponse> => {
  if (calculation.type === CalculationType.Stages) {
    const { effectiveDate, period, latestUtrsData } = getLatestActualHistoricalUtrs(utrsData);
    const chartData: ChartColumn[][] = [
      ['Key', 'Value'],
      ...(await Promise.all(
        calculation.values.map(async (value) => [
          value.name,
          await processCalculation({
            variables,
            utrsData: latestUtrsData,
            stages: value.stages,
            fallback: 0,
          }),
        ])
      )),
    ];
    return { chartData, effectiveDate, period };
  }

  const { latestActualUtrvs = [], effectiveDate, period } = getLatestActualUtrvs(utrsData);
  const variablesWithValues = getVariablesWithValues({ utrsData, variables, utrvs: latestActualUtrvs });
  const chartData = [
    ['Key', 'Value'],
    ...calculation.values.map((value) => [
      value.name,
      tryCalculation({ formula: value.formula ?? '', variables: variablesWithValues, fallback: 0 }),
    ]),
  ];
  return { chartData, effectiveDate, period };
};

const CHART_SUBTYPE_STYLE_NAME_MAP = {
  [ChartSubType.FullPie]: chartStyleNames.chartStyleFullPie,
  [ChartSubType.PieWithLegend]: chartStyleNames.chartStylePieLegend,
  [ChartSubType.Pie]: '',
} as const;

export const PieChart = ({ utrsData, variables, calculation, subType, overrideOptions }: Props) => {
  const [data, setData] = useState<ChartDataResponse | undefined>();

  useEffect(() => {
    if (calculation) {
      getChartData({ utrsData, variables, calculation }).then((data) => setData(data));
    }
  }, [calculation, utrsData, variables]);

  if (!utrsData.length || !variables || !calculation || !data) {
    return null;
  }

  const { chartData, effectiveDate = '', period } = data;

  return (
    <>
      <div className='w-100 h-100'>
        <Chart
          chartType={ChartType.PieChart}
          data={chartData}
          width={'100%'}
          height={'100%'}
          options={{
            ...getChartOptions({
              chartType: ChartType.PieChart,
              options: chartStyleNames[CHART_SUBTYPE_STYLE_NAME_MAP[subType]],
            }),
            ...overrideOptions,
          }}
        />
      </div>
      <TakenFromText effectiveDate={effectiveDate} period={period} />
    </>
  );
};
