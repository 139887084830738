import { ROUTES } from '@constants/routes';
import { InsightPage } from '@routes/summary/insights/utils/constants';
import { getValidPage, getInsightPageOptions, isInsightLayoutPage } from '@routes/summary/insights/utils/helpers';
import { InsightDashboardOption } from '@routes/summary/insights/utils/sidebar';
import { generateUrl } from '@routes/util';
import { useHistory } from 'react-router-dom';

interface Props {
  initiativeId: string;
  portfolioId: string;
  summaryPage: InsightPage;
}

export const usePTCompanyCustomDashboards = ({
  initiativeId,
  portfolioId,
  summaryPage: summaryPageParam = InsightPage.Overview,
}: Props) => {
  const history = useHistory();
  const summaryPage = isInsightLayoutPage(summaryPageParam) ? summaryPageParam : undefined;

  const handleNavigate = (page: string) => {
    const url = generateUrl(ROUTES.PORTFOLIO_TRACKER_COMPANY, {
      portfolioId,
      companyId: initiativeId,
      summaryPage: page !== InsightPage.Overview ? page : undefined,
    });
    history.push(url);
  };

  const handleClickOption = (item: InsightDashboardOption) => {
    return item.disabled ? undefined : handleNavigate(item.value);
  };

  return {
    currentPage: getValidPage(summaryPage),
    options: getInsightPageOptions(),
    handleNavigate,
    handleClickOption,
  };
};
