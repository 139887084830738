import { DashboardRow, DashboardSection } from '@components/dashboard';
import { CustomReportRoutes } from '@components/downloads/CustomReportsRoute';
import { useTabs } from '@hooks/useTabs';
import { generateUrl } from '@routes/util';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Form, OnChangeForm } from './types';
import { Configuration } from './Configuration';
import { Columns } from './Columns';
import { UtrvStatus } from '@constants/status';
import { VisibilityStatus } from '@g17eco/types/download';
import { useCreateCustomReportMutation, useUpdateCustomReportMutation } from '@api/custom-reports';
import {
  CustomReportProps,
  CustomReportTemplate,
  CustomReportType,
  NewCustomReportTemplate,
  Ordering,
  OrderingType,
} from '@g17eco/types/custom-report';
import { Navigation, NavTab } from '@components/company-settings/Navigation';
import { generateErrorToast, generateToast } from '@components/toasts';
import { getDefaultScope } from '@utils/survey-scope';
import SimpleTooltip from '@components/simple-tooltip';
import './style.scss';
import { compareStringArrays } from '@utils/string';
import { DEFAULT_ORDERING_DIRECTION } from './utils';
import { DisplayOption, getDownloadDisplayOptions } from '@components/downloads/util/downloadReportHandler';
import { useToggle } from '@hooks/useToggle';
import { PreviewModal } from './PreviewModal';

export enum Tabs {
  Configuration = 'configuration',
  Headings = 'headings',
}

const getNavTabs = (isHeadingsDisabled: boolean): NavTab[] => [
  {
    code: Tabs.Configuration,
    label: 'Configuration',
  },
  {
    code: Tabs.Headings,
    label: 'Headings',
    disabled: isHeadingsDisabled,
  },
];

const metricOverridesDisplayConfig = getDownloadDisplayOptions(DisplayOption.UserInput);
const defaultScope = getDefaultScope();
const getForm = (customReport?: CustomReportTemplate): Form => {
  const { _id, name = '', description, config, survey: surveyConfig } = customReport ?? {};
  const {
    ids: surveyIds = [],
    statuses = [UtrvStatus.Verified],
    assuranceStatus,
    visibility = VisibilityStatus.Include,
    scope = defaultScope,
    displayMetricOverrides = metricOverridesDisplayConfig.displayMetricOverrides,
    displayUserInput = metricOverridesDisplayConfig.displayUserInput,
  } = surveyConfig ?? {};
  const { columns = [], ordering } = config ?? {};

  return {
    _id,
    name,
    description,
    surveyIds,
    metricFilters: {
      statuses,
      assuranceStatus,
      visibility,
      scope,
      displayMetricOverrides,
      displayUserInput,
    },
    columns,
    ordering: ordering ?? {
      type: OrderingType.Default,
      columns: columns[0] ? [{ code: columns[0].code, direction: DEFAULT_ORDERING_DIRECTION }] : [],
    },
  };
};

const getOrderingIfColumnsChanged = ({
  ordering,
  columns,
  newColumns,
}: {
  newColumns: Form['columns'];
} & Pick<Form, 'columns' | 'ordering'>): Ordering => {
  const { removed: removedColumnCodes } = compareStringArrays({
    oldArray: columns.map((column) => column.code),
    newArray: newColumns.map((column) => column.code),
  });

  const newOrderingColumns = ordering.columns.filter((column) => !removedColumnCodes.includes(column.code));

  if (newOrderingColumns.length) {
    return { ...ordering, columns: newOrderingColumns };
  }

  return {
    type: OrderingType.Default,
    columns: newColumns[0] ? [{ code: newColumns[0].code, direction: DEFAULT_ORDERING_DIRECTION }] : [],
  };
};

export const CustomReportTemplateBuilder = ({
  initiativeId,
  customReport,
}: CustomReportProps<CustomReportTemplate>) => {
  const history = useHistory();

  const [form, setForm] = useState<Form>(() => getForm(customReport));
  const [isChanged, setIsChanged] = useState(false);
  const [previewing, togglePreview] = useToggle();

  const handleChangeForm: OnChangeForm = (changes) => {
    // Update ordering in case ordering columns are removed.
    if ('columns' in changes && changes.columns) {
      changes.ordering = getOrderingIfColumnsChanged({
        ordering: form.ordering,
        columns: form.columns,
        newColumns: changes.columns,
      });
    }

    setForm((currentForm) => ({
      ...currentForm,
      ...changes,
    }));
    if (!isChanged) {
      setIsChanged(true);
    }
  };
  const { currentTab, handleChangeTab } = useTabs({ initialTab: Tabs.Configuration });

  const [createCustomReport, { isLoading: isCreating }] = useCreateCustomReportMutation();
  const [updateCustomReport, { isLoading: isUpdating }] = useUpdateCustomReportMutation();

  const handleCancel = () => {
    const url = generateUrl(CustomReportRoutes.ROOT, { initiativeId });
    history.push(url);
  };

  const isDisabled = !form.name || form.surveyIds.length === 0 || isCreating || isUpdating || !isChanged;

  const handleSubmit = () => {
    if (isDisabled) {
      return;
    }

    const data: NewCustomReportTemplate = {
      type: CustomReportType.Template,
      name: form.name,
      description: form.description,
      survey: {
        ids: form.surveyIds,
        ...form.metricFilters,
      },
      config: {
        columns: form.columns,
        ordering: form.ordering,
      },
    };

    const method = form._id
      ? updateCustomReport({ initiativeId, reportId: form._id, formData: data })
      : createCustomReport({ initiativeId, formData: data });
    method
      .unwrap()
      .then(({ _id: reportId }) => {
        setIsChanged(false);
        generateToast({
          title: `Custom report ${form._id ? 'saved' : 'created'}`,
          color: form._id ? 'info' : 'success',
          message: `Custom report template has been ${form._id ? 'updated' : 'created'}`,
        });

        // Go to headings tab after creating template
        if (!form._id) {
          setForm((currentForm) => ({ ...currentForm, _id: reportId }));
          handleChangeTab(Tabs.Headings);
        }
      })
      .catch((error) => {
        generateErrorToast(error);
      });
  };

  const getTooltip = () => {
    if (form._id && !form.surveyIds.length) {
      return 'Please add a data source to save';
    }

    if (!!customReport && !form.columns.length) {
      return 'Please add headings to save';
    }

    return '';
  };

  const buttons = (
    <div className='d-flex justify-content-end gap-3'>
      <Button color='link-secondary' onClick={handleCancel}>
        Cancel
      </Button>
      <SimpleTooltip text={getTooltip()}>
        <Button color='primary' onClick={handleSubmit} disabled={isDisabled}>
          {form._id ? 'Save' : 'Create report'}
        </Button>
      </SimpleTooltip>
    </div>
  );

  return (
    <>
      <DashboardRow>
        <div className='w-100 d-flex justify-content-between align-items-center'>
          <h3>Custom report builder</h3>
          {buttons}
        </div>
      </DashboardRow>
      <DashboardSection paddingInternal={0}>
        <div className='d-flex flex-column'>
          <div className='mb-3'>
            <Navigation
              navTabs={getNavTabs(!form._id)}
              page={currentTab}
              onClick={(tab) => handleChangeTab(tab as Tabs)}
            />
          </div>
          {currentTab === Tabs.Configuration ? (
            <Configuration initiativeId={initiativeId} form={form} onChangeForm={handleChangeForm} />
          ) : null}
          {currentTab === Tabs.Headings ? (
            <Columns form={form} onChangeForm={handleChangeForm} isEditing={!!customReport} />
          ) : null}
          <div className='mt-3 d-flex justify-content-between'>
            <Button color='secondary' onClick={togglePreview} key={`template-builder-preview-${previewing}`}>
              <i className='fal fa-eye mr-2' />
              Preview
            </Button>
            {buttons}
          </div>
        </div>
      </DashboardSection>
      <PreviewModal open={previewing} toggle={togglePreview} columns={form.columns} />
    </>
  );
};
