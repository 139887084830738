import { Button } from 'reactstrap';
import { InsightDashboardItemType, InsightDashboardTempItemType } from '../../../types/insight-custom-dashboard';
import { WidgetProps } from '../../../routes/custom-dashboard/utils';
import { FeatureStability } from '@g17eco/molecules';

type Type =
  | InsightDashboardItemType.Headline
  | InsightDashboardItemType.Table
  | InsightDashboardItemType.Media
  | InsightDashboardTempItemType
  | InsightDashboardItemType.Space;

interface AddWidgetBtnProps {
  type: Type;
  handleClick: () => void;
}

export const AddWidgetBtn = ({ type, handleClick }: AddWidgetBtnProps) => (
  <Button outline color='secondary' onClick={handleClick}>
    <i className={`${WidgetProps[type].icon} mr-2`}></i>
    <span>Add {WidgetProps[type].label}</span>
    {WidgetProps[type].isStaffFeature ? <FeatureStability stability='beta' /> : null}
  </Button>
);
