import { UtrVariable, UtrVariables } from '@routes/summary/insights/utils/constants';
import {
  Calculation,
  CalculationType,
  CalculationValue,
  ChartSubType,
  InsightDashboardItemChartType,
  InsightDashboardItemType,
  MediaFile,
  ValueRole,
} from '@g17eco/types/insight-custom-dashboard';
import { ExtendedUtrVariable } from '@routes/custom-dashboard/types';
import { CHART_COLORS, ChartData, QuestionData, isDashboardItem } from '@routes/custom-dashboard/utils';
import { UtrValueType } from '@g17eco/types/universalTracker';
import { getExtendedGoalCode } from '@utils/sdg';
import { getSDGTitle } from '@constants/sdg-data';
import { ChartItem, QuestionsMapUtr } from '../types';
import { ChartUtrData } from '@routes/custom-dashboard/items/types';

export type MetricRemoveFn = (utrVariable: UtrVariable, index: number) => void;

/**
 * Handle a metric removal from the list of metrics.
 * It's possible to have same matching questions in the list, so we need to handle that.
 */
export const removeMetric = (metrics: UtrVariable[], removeVariable: UtrVariable, index: number): UtrVariable[] => {
  if (!metrics?.length) {
    return [];
  }

  // use reference to check, as long as we do not modify the original object
  const removeIndex = metrics.findIndex((metric) => metric === removeVariable);
  if (removeIndex !== -1) {
    return metrics.filter((_, i) => i !== removeIndex);
  }

  // Fallback to checking index which should be correct always, but double check.
  if (metrics[index]?.code === removeVariable.code) {
    // Don't have valueListCode or it must match
    if (!removeVariable.valueListCode || metrics[index].valueListCode === removeVariable.valueListCode) {
      return metrics.filter((_, i) => i !== index);
    }
  }

  // Original logic that does not handle multiple same questions.
  return metrics.filter((metric) => {
    if (removeVariable.valueListCode) {
      return metric.valueListCode !== removeVariable.valueListCode || metric.code !== removeVariable.code;
    }
    return metric.code !== removeVariable.code;
  });
};

/**
 * Table depends on the items or size of text and
 * require to recalculate the grid.
 */
export const shouldRecalculateGrid = (updatingItem: { type: string }) => {
  switch (updatingItem.type) {
    case InsightDashboardItemType.Table:
      return true;
    case InsightDashboardItemType.Headline:
    case InsightDashboardItemType.Text:
    case InsightDashboardItemType.SDGTracker:
    case InsightDashboardItemType.Chart:
    case InsightDashboardItemType.Media:
    default:
      return false;
  }
};

export const getUniqueUtrVariables = (extendedUtrVariables: ExtendedUtrVariable[]) => {
  return extendedUtrVariables.reduce((acc, utrVariable) => {
    if (acc.length === 0) {
      return acc.concat(utrVariable);
    }
    const isNew = acc.every(
      ({ code: existingCode, valueListCode: existingValueListCode = '' }) =>
        existingCode !== utrVariable.code || existingValueListCode !== utrVariable.valueListCode
    );
    if (isNew) {
      return acc.concat(utrVariable);
    }
    return acc;
  }, [] as ExtendedUtrVariable[]);
};

export const checkIsVideo = (file: Pick<MediaFile, 'type'>) => file.type.includes('video');

enum MetricCountType {
  Single = 'single',
  Multiple = 'multiple',
}

const supportedChartTypeMap = {
  [MetricCountType.Single]: [ChartSubType.SingleValue, ChartSubType.SparkLine, ChartSubType.Line],
  [MetricCountType.Multiple]: [
    ChartSubType.Line,
    ChartSubType.Column,
    ChartSubType.Bar,
    ChartSubType.Pie,
    ChartSubType.FullPie,
  ],
};

export const doSupportMultiple = (subType: ChartSubType) => {
  return supportedChartTypeMap[MetricCountType.Multiple].includes(subType);
};

export const isMultipleMetricsChart = ({ subType, metrics }: ChartData) => {
  return subType === ChartSubType.Line ? metrics.length > 1 : doSupportMultiple(subType);
};

export const emptyUtrData = {
  code: undefined,
  valueListCode: undefined,
  groupCode: undefined,
  subGroupCode: undefined,
};

export const isValidUtrVariable = (questionData?: QuestionData): questionData is UtrVariable => {
  if (!questionData) {
    return false;
  }
  return Boolean(questionData.code);
};

const getName = ({
  utrVariable,
  chartType,
  questionsMap,
}: {
  utrVariable: ExtendedUtrVariable;
  chartType: InsightDashboardItemChartType;
  questionsMap: Map<string, QuestionsMapUtr>;
}): string => {
  return utrVariable.name || getChartTitleByType({ chartType, utrVariable, questionsMap });
};

export const getChartCalculation = ({
  metrics,
  variables,
  subType,
  questionsMap,
  chartType,
}: {
  metrics: ExtendedUtrVariable[];
  variables: UtrVariables;
  chartType: InsightDashboardItemChartType;
  subType: ChartSubType;
  questionsMap: Map<string, QuestionsMapUtr>;
}): Calculation => {
  switch (subType) {
    case ChartSubType.Bar: {
      const barChartCalculation = metrics.reduce<{
        calculations: CalculationValue[];
        currentColorIndex: number;
      }>(
        (acc, current) => {
          const utrVariable = Object.entries(variables).find(
            ([_key, value]) => value.valueListCode === current.valueListCode && value.code === current.code
          );

          if (!utrVariable) {
            return acc;
          }

          const name = getName({ utrVariable: current, chartType, questionsMap });
          const calculation = {
            name: name,
            formula: `{${utrVariable[0]}}`,
            options: {
              tooltip: {
                formula: `${name} | {${utrVariable[0]}}`,
              },
              style: CHART_COLORS[acc.currentColorIndex],
            },
          };
          acc = {
            calculations: acc.calculations.concat(calculation),
            currentColorIndex: acc.currentColorIndex + 1,
          };
          return acc;
        },
        { calculations: [], currentColorIndex: 0 }
      ).calculations;

      return {
        type: CalculationType.Formula,
        values: barChartCalculation,
        headers: [
          {
            name: '',
          },
          {
            name: '',
          },
          {
            role: ValueRole.Annotation,
          },
          {
            role: ValueRole.Style,
          },
        ],
      };
    }
    case ChartSubType.Line:
    case ChartSubType.Column:
    default: {
      const lineChartCalculation = metrics.reduce<CalculationValue[]>((acc, current) => {
        // Need to use entries as it will return entry for the use below
        const utrVariable = Object.entries(variables).find(
          ([_key, value]) => value.valueListCode === current.valueListCode && value.code === current.code
        );

        if (!utrVariable) {
          return acc;
        }

        acc.push({
          name: getName({ utrVariable: current, chartType, questionsMap }),
          formula: `{${utrVariable[0]}}`,
        });
        return acc;
      }, []);

      return {
        type: CalculationType.Formula,
        values: lineChartCalculation,
      };
    }
  }
};

const getValueListName = ({
  utr,
  valueListCode,
}: {
  utr: QuestionsMapUtr;
  valueListCode: string;
}): string => {
  switch (utr.valueType) {
    case UtrValueType.ValueList:
    case UtrValueType.NumericValueList:
    case UtrValueType.TextValueList:
      return utr.valueValidation?.valueList?.list?.find((vl) => vl.code === valueListCode)?.name ?? utr.valueLabel;
    case UtrValueType.Table: {
      const column = utr.valueValidation?.table?.columns.find((c) => c.code === valueListCode);
      if (!column) {
        return utr.valueLabel;
      }
      return column.shortName ?? column.name;
    }
    default:
      return utr.valueLabel;
  }
};

export const getUtrTitle = ({
  code,
  valueListCode,
  questionsMap,
}: {
  code: string;
  valueListCode: string | undefined;
  questionsMap: Map<string, QuestionsMapUtr>;
}) => {
  const question = questionsMap.get(code);
  if (!question) {
    return '';
  }
  if (valueListCode) {
    return getValueListName({ utr: question, valueListCode });
  }
  return question.valueLabel;
};

export const getChartTitleByType = ({
  chartType,
  utrVariable,
  questionsMap,
}: {
  chartType: InsightDashboardItemChartType;
  utrVariable: ExtendedUtrVariable;
  questionsMap: Map<string, QuestionsMapUtr>;
}) => {
  const { code, valueListCode } = utrVariable;
  if (chartType === InsightDashboardItemType.Chart) {
    return getUtrTitle({ code, valueListCode, questionsMap });
  }

  if (chartType === InsightDashboardItemType.SDGTracker) {
    const goalCode = getExtendedGoalCode(code);
    return goalCode ? getSDGTitle({ goalCode }) : '';
  }

  if (chartType !== InsightDashboardItemType.Integration || !utrVariable.integrationCode) {
    return '';
  }
  return getUtrTitle({ code, valueListCode, questionsMap });
};

export const checkIsCustomTitle = ({
  item,
  chartData,
  questionsMap,
}: {
  item: ChartItem;
  chartData: Pick<ChartData, 'title'>;
  questionsMap: Map<string, QuestionsMapUtr>;
}) => {
  if (!isDashboardItem(item)) {
    // is adding.
    return Boolean(chartData.title);
  }

  const metrics = Object.values(item.variables);
  if (!metrics.length) {
    return false;
  }

  const [firstMetric] = metrics;

  const defaultTitle =
    item.type === InsightDashboardItemType.Chart
      ? getUtrTitle({ code: firstMetric.code, valueListCode: firstMetric.valueListCode, questionsMap })
      : getSDGTitle({ goalCode: getExtendedGoalCode(firstMetric.code) ?? '' });

  return chartData.title !== defaultTitle;
};

export const getLatestMetricText = ({
  utrsData,
  item,
}: {
  utrsData: ChartUtrData[];
  item: { variables?: UtrVariables };
}) => {
  const metricCode = Object.values(item.variables ?? {})?.[0].code;
  const utrv = utrsData.find((utrData) => utrData.utr.code === metricCode)?.utrvs.slice(-1)[0];

  return (utrv?.valueData?.data as string) ?? '';
};
