import { DecimalInputProps } from '@components/utr-decimal/DecimalInput';
import { Option, SelectFactory, SelectTypes } from '@g17eco/molecules';
import { Label } from 'reactstrap';
import { UnitOverrideProps } from '../unit-override/OverrideUnitForm';
import { GLOBAL_UPDATE_CODE, InputOverrideType, UnitConfigType, UnitTypeMapValue } from '../utils';
import { InputOverrideField } from './InputOverrideField';
import SimpleTooltip from '@components/simple-tooltip';
import { useContext } from 'react';
import { QuestionManagementContext } from '../../../apps/company-tracker/components/admin-dashboard/questions/QuestionManagementContainer';

const inputOverrideTypeOptions: Option<InputOverrideType>[] = [
  {
    label: 'Number scale',
    value: InputOverrideType.NumberScale,
  },
  {
    label: 'Decimals',
    value: InputOverrideType.Decimal,
  },
  {
    label: 'Units',
    value: InputOverrideType.Unit,
  },
];

interface Props extends DecimalInputProps, Omit<UnitOverrideProps, 'unitTypeMap' | 'type'> {
  unitTypeMap: Record<string, Map<string, UnitTypeMapValue>>;
  unitConfig: UnitConfigType;
  hasAnythingChanged: boolean;
}

export const MultipleInputOverrideGroup = (props: Props) => {
  const { unitTypeMap, hasAnythingChanged, ...rest } = props;
  const { inputOverrideType, setInputOverrideType } = useContext(QuestionManagementContext);
  if (!inputOverrideType) {
    return null;
  }

  return (
    <div>
      <Label className='mb-1'>
        <span className='fw-semibold'>Override</span>
        {hasAnythingChanged ? (
          <SimpleTooltip text='Please save or cancel your change before making additional input overrides'>
            <i className='fal fa-bell-exclamation text-ThemeWarningMedium ml-2' />
          </SimpleTooltip>
        ) : null}
      </Label>
      <SelectFactory
        selectType={SelectTypes.SingleSelect}
        options={inputOverrideTypeOptions}
        onChange={(op) => op?.value && setInputOverrideType(op.value)}
        isSearchable={false}
        value={inputOverrideTypeOptions.find((op) => op.value === inputOverrideType) ?? null}
        className='mb-3'
        isDisabled={hasAnythingChanged}
        isMenuPortalTargetBody
      />

      <InputOverrideField {...rest} showEmptyAlert type={inputOverrideType} unitTypeMap={unitTypeMap[GLOBAL_UPDATE_CODE]} />
    </div>
  );
};
