import { Popover, PopoverBody, PopoverProps, UncontrolledPopover } from 'reactstrap';
import './style.scss';
import { ADD_DASHBOARD_OPTION, InsightDashboardOption } from '@routes/summary/insights/utils/sidebar';
import { useRef } from 'react';
import { useClickOutside } from '@hooks/useClickOutside';
import { DashboardTemplateType, TemplateOption } from '@g17eco/types/insight-custom-dashboard';

const BLANK_OPTION: TemplateOption = {
  label: 'Blank template',
  value: ADD_DASHBOARD_OPTION,
  icon: 'fal fa-square-plus',
};
const getOptions = (templates: TemplateOption[]): TemplateOption[] => {
  return [BLANK_OPTION, ...templates];
};

interface Props {
  templates: TemplateOption[];
  open?: boolean;
  toggle?: () => void;
  isUncontrolled?: boolean;
  target: string;
  onClickOption: (value: InsightDashboardOption) => void;
  onClickOutside?: () => void;
}

export const TemplateMenuPopover = ({
  templates,
  open,
  toggle,
  isUncontrolled,
  target,
  onClickOption,
  onClickOutside,
}: Props) => {
  const popoverRef = useRef<HTMLDivElement>(null);
  useClickOutside(popoverRef, onClickOutside);

  if (!templates.length) {
    return null;
  }

  const options = getOptions(templates);

  const body = (
    <PopoverBody>
      <div ref={popoverRef}>
        {options.map(({ value, label, icon }) => {
          const isBlankTemplate = value === BLANK_OPTION.value;

          return (
            <div
              key={value}
              onClick={() => value !== DashboardTemplateType.GPT && onClickOption({ value, label })}
              className={`insights-dashboard__template-menu-popover__item d-flex align-items-center text-md px-3 py-1 ${
                value !== DashboardTemplateType.GPT ? '' : 'disabled'
              }`}
            >
              {icon && isBlankTemplate ? <i className={`${icon} mr-2 text-ThemeIconSecondary`} /> : null}
              {icon && !isBlankTemplate ? (
                <img src={icon} className='mr-2 object-fit-cover ' style={{ width: 16, height: 16 }} />
              ) : null}
              {label}
            </div>
          );
        })}
      </div>
    </PopoverBody>
  );

  const commonProps = {
    placement: 'right-start',
    container: 'body',
    hideArrow: true,
    trigger: 'focus',
    target,
    className: 'insights-dashboard__template-menu-popover',
  } satisfies PopoverProps;

  if (isUncontrolled) {
    return <UncontrolledPopover {...commonProps}>{body}</UncontrolledPopover>;
  }

  return open ? (
    <Popover {...commonProps} isOpen={open} toggle={toggle}>
      {body}
    </Popover>
  ) : null;
};
